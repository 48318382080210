import React, { Component } from "react";
import Datetime from "react-datetime";
import { Row, Column } from "simple-flexbox";
import { Panel } from "react-bootstrap";
import TextareaAutosize from 'react-textarea-autosize';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import PageHeader from "@sm/components/PageHeader";
import Form from "@sm/core/Form";
import Multiselect from "@sm/components/custom/customMultiselect";
import RunningBalanceComponent from "@modules/data/components/RunningBalanceComponent";
import Select from "@sm/components/custom/Select";
import Spinner from "@sm/components/Spinner";
import UpdateSettlementComponent from "@modules/data/components/UpdateSettlementComponent";
import WithheldFundsComponent from "@modules/data/components/WithheldFundsComponent";

import "@assets/css/manualInput.css";
import Calendar from "@assets/images/calendar.png";
import ManualInputIcon from "@assets/images/notes.png";

import { crudActions } from "@sm/services/crudActions";
import { FETCH_PERMISSIONS } from "@sm/actions/types";
const store = require("@sm/reducers/index");

class ManualInput extends Component {
  fieldsMap = {
    SETTLEMENT_BY_DATE: [
      [
        {
          name: "currency",
          label: "MID Currency",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "availableBalance",
          label: "Total Available Balance",
          fieldType: "input",
          disabled: true,
        },
        {
          name: "balanceToSettle",
          label: "Available Balance Reduction",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "balanceToSettle",
          label: "Balance to Settle",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "propertyId",
          label: "Status",
          fieldType: "select",
          items: "settlementByAmountStatuses",
        },
      ],
    ],
    SETTLEMENT_BY_AMOUNT: [
      [
        {
          name: "currency",
          label: "MID Currency",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "availableBalance",
          label: "Total Available Balance",
          fieldType: "input",
          disabled: true,
        },
        {
          name: "requestedSettlement",
          label: "Available Balance Reduction",
          fieldType: "input",
        },
        {
          name: "balanceToSettle",
          label: "Balance to Settle",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "propertyId",
          label: "Status",
          fieldType: "select",
          items: "settlementByAmountStatuses",
        },
      ],
    ],
    SETTLEMENT_BY_REQUEST: [
      [
        {
          name: "currency",
          label: "MID Currency",
          disabled: true,
          fieldType: "input",
          flexGrow: "2",
        },
        {
          name: "availableBalance",
          label: "Total Available Balance",
          disabled: true,
          fieldType: "input",
          flexGrow: "10",
        },
        {
          name: "requestedSettlement",
          label: "Requested Settlement",
          fieldType: "input",
          flexGrow: "10",
        },
        {
          name: "balanceToSettle",
          label: "Balance to Settle",
          disabled: true,
          fieldType: "input",
          flexGrow: "10",
        },
        {
          name: "propertyId",
          label: "Status",
          fieldType: "select",
          items: "settlementByRequestStatuses",
          flexGrow: "10",
        },
      ],
    ],
    UPDATE_SETTLEMENT: [
      [
        {
          name: "name",
          label: "MID",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "fromDate",
          label: "From Date",
          disabled: true,
          fieldType: "date",
          display: "showDates",
        },
        {
          name: "toDate",
          label: "To Date",
          disabled: true,
          fieldType: "date",
          display: "showDates",
        },
        {
          name: "date",
          label: "Date",
          disabled: true,
          fieldType: "date",
        },
        {
          name: "balanceToSettle",
          label: "Balance to Settle",
          disabled: true,
          fieldType: "input",
        },
        {
          name: "propertyId",
          label: "Update Status",
          fieldType: "select",
          items: "updateSettlementStatuses",
        },
      ],
    ],
    TOP_UP_PAYOUT_WALLET: [
      [
        {
          name: "midCurrency",
          label: "MID Currency",
          disabled: true,
          fieldType: "input",
          flexGrow: "4",
        },
        {
          name: "availableBalance",
          label: "Available Balance",
          disabled: true,
          fieldType: "input",
          flexGrow: "10",
        },
        {
          name: "topUpAmount",
          label: "Top Up Amount",
          fieldType: "input",
          flexGrow: "10",
        }
      ]
    ],
    SETTLE_AGENT: [
      [
        {
          name: "amount",
          label: "Amount",
          fieldType: "input",
          flexGrow: "10",
          inputType: "number"
        },
        {
          name: "notes",
          label: "Notes",
          fieldType: "textarea",
          flexGrow: "10",
        }
      ]
    ],
    DEBIT_CREDIT: [],
    OTC_CASHOUT: []
  };

  formsMap = {
    SETTLEMENT_BY_DATE: "settlementFormByDate",
    SETTLEMENT_BY_AMOUNT: "settlementFormByAmount",
    SETTLEMENT_BY_REQUEST: "settlementFormByRequest",
    UPDATE_SETTLEMENT: "updateSettlementForm",
    TOP_UP_PAYOUT_WALLET: "topUpPayoutWalletForm",
    SETTLE_AGENT: "settleAgentForm",
    DEBIT_CREDIT: "debitCreditForm",
    OTC_CASHOUT: "otcCashoutForm",
    OTC_CASHOUT_UPDATE: "otcCashoutUpdateForm"
  };
  otcCashoutUpdate = "OTC_CASHOUT_UPDATE";
  collectedStatusValue = "COLLECTED";

  state = {
    runningBalanceTypes: [],
    runningBalanceValues: [],
    settlementStatuses: [],
    settlementByAmountStatuses: [],
    settlementByRequestStatuses: [],
    midsForDebit: [],
    midsForCredit: [],
    creditPsps: [],
    settlementDates: [],
    chosenCredits: [],

    settlementFormByDate: new Form(
      {
        availableBalance: "",
        balanceToSettle: "",
        // eslint-disable-next-line no-dupe-keys
        balanceToSettle: "",
        propertyId: "",
      },
      [
        {
          name: "availableBalance",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "balanceToSettle",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "balanceToSettle",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "propertyId",
          type: "isString",
          rules: {
            required: true,
          },
        }
      ]
    ),

    settlementFormByAmount: new Form(
      {
        availableBalance: "",
        requestedSettlement: "",
        balanceToSettle: "",
        propertyId: "",
      },
      [
        {
          name: "availableBalance",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "requestedSettlement",
          type: "isString",
          rules: {
            required: false,
          },
        },
        {
          name: "balanceToSettle",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "propertyId",
          type: "isString",
          rules: {
            required: true,
          },
        }
      ]
    ),

    settlementFormByRequest: new Form(
      {
        availableBalance: null,
        requestedSettlement: null,
        balanceToSettle: null,
        propertyId: "",
      },
      [
        {
          name: "propertyId",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "availableBalance",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "requestedSettlement",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "balanceToSettle",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
      ]
    ),

    updateSettlementForm: new Form(
      {
        propertyId: "",
      },
      [
        {
          name: "propertyId",
          type: "isString",
        },
      ]
    ),

    topUpPayoutWalletForm: new Form(
      {
        midCurrency: "",
        availableBalance: "",
        topUpAmount: ""
      },
      [
        {
          name: "midCurrency",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "availableBalance",
          type: "isNumber",
          rules: {
            min: 0,
            required: true,
          },
        },
        {
          name: "topUpAmount",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ),

    settleAgentForm: new Form(
      {
        amount: "",
        notes: ""
      },
      [
        {
          name: "amount",
          type: "isNumber",
          rules: {
            required: true,
          },
        },
        {
          name: "notes",
          type: "isString",
          rules: {
            required: false,
          }
        }
      ]
    ),

    otcCashoutFormState :  {},
    debitCreditForm: [],
    otcCashoutForm : [],
    otcCashoutUpdateForm : [],
    otcCashoutInvoiceDates: [],
    invoices: [],
    otcCashoutInvoiceFullInfo: [],
    filteredFxPairs: [],

    date: Datetime.moment().utc(),
    lastUpdate: Datetime.moment().format("HH:mm - DD. MMM YYYY"),
    lastSettlementDate: "",
    loadingLookups: true,
    loadingDataInput: false,
    selectedMidCurrency: [],

    controls: {},
    controlsFormRules: [
      {
        name: "company",
        type: "isString",
        rules: {
          required: true,
        },
      },
      {
        name: "merchant",
        type: "isString",
        rules: {
          required: true,
        },
      },
      {
        name: "dataType",
        type: "isString",
        rules: {
          required: true,
        },
      }
    ],
    controlsFormData: {
      company: "",
      merchant: "",
      dataType: "",
      psp: "",
      midIds: "",
      settlementStatus: "",
      date: "",
    },

    companiesClients: {},
    companiesPsps: {},
    companiesAccounts: {},
    companiesFxPairs: {},
    companiesBaseCurrencies: {},
    companies: [],
    otcActionType: [{value: "OTC_CASHOUT", label: "Collect"}, {value: this.otcCashoutUpdate, label: "Update Status"}],
    psps: [],
    mids: [],
    dataTypes: [],
    notes: "",

    temporaryRunningBalanceItem: {},
    temporaryWithheldItem: {},
    savedData: {},

    settlementMids: [],
    otcCashoutStatuses: [],

    access: [],
    pageType: "settlements",

    showAlert: false,
    alertType: "",
    alertMessage: "",
    submitClicked: false,
    saveClicked: false,
    lockButton: false,
    enableReduce: false,
    chooseOtcInvoice: false
  };

  subscribeFunction = null;

  componentDidMount() {
    const { controlsFormRules, controlsFormData } = this.state;
    const { match } = this.props;
    const isIncomingFunds = match && match.path === "/incoming-funds";
    if (isIncomingFunds) {
      const merchantField = controlsFormRules.find(item => item.name === "merchant");
      merchantField.rules.required = false;
    }
    this.setState({
      controls: isIncomingFunds ? this.defineControlsForm("OTC_CASHOUT") : new Form(controlsFormData, controlsFormRules),
      loading: false,
      timeStamp: Datetime.moment().utc(),
      pageType: isIncomingFunds ? "incomingFunds" : "settlements"
    });

    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access,
        });
      }
    });

    Promise.all([
      crudActions.get("v1/data/input/lookups"),
      crudActions.get("v1/agent/all")
    ]).then((info) => {
      const data = info[0];
      const agents = info[1];

      if (data) {
        if(!isIncomingFunds) {
          data.dataTypes = data.dataTypes.filter(elem => elem.id !== "OTC_CASHOUT" && elem.id !== this.otcCashoutUpdate);
        }
        if (isIncomingFunds) {
          data.whiteLabels = data.whiteLabels.filter(comp => comp.otcEnabled);
        }
        const companies = data.whiteLabels.map((elem) => elem.whitelabel);
        companies.sort((elemA, elemB) =>
          elemA.label.localeCompare(elemB.label)
        );
        const runningBalanceTypes = data.dataTypes.find(
          (elem) => elem.id === "RUNNING_BALANCE"
        ).properties;
        const settlementStatuses = data.dataTypes.find(
          (elem) => elem.id === "SETTLEMENT_BY_DATE"
        ).properties;

        const otcCashoutStatuses = isIncomingFunds ? data.dataTypes.find(
          (elem) => elem.id === "OTC_CASHOUT"
        ).properties : [];
        const midCurrency = data.mids.map((mid) => {
          return {
            value: mid.id,
            label: mid.currency,
          };
        });
        const uniqueCurrencies = [];

        const currency = midCurrency.filter((element) => {
          const isDuplicate = uniqueCurrencies.includes(element.label);

          if (!isDuplicate) {
            uniqueCurrencies.push(element.label);

            return true;
          }

          return false;
        });

        this.setState({
          companies: companies,
          controls: Object.assign(this.state.controls, {
            company: companies[0] ? companies[0].value : "",
          }),
          companiesClients: data.whiteLabels.reduce(
            (accumulator, currentElem) => {
              accumulator[currentElem.whitelabel.value] =
                  currentElem.merchants;
              return accumulator;
            },
            {}
          ),
          companiesPsps: data.whiteLabels.reduce(
            (accumulator, currentElem) => {
              accumulator[currentElem.whitelabel.value] = currentElem.psp;
              return accumulator;
            },
            {}
          ),
          companiesAccounts: data.whiteLabels.reduce(
            (accumulator, currentElem) => {
              accumulator[currentElem.whitelabel.value] = currentElem.pspAccounts || [];
              accumulator[currentElem.whitelabel.value] = accumulator[currentElem.whitelabel.value] ? accumulator[currentElem.whitelabel.value].map(({ id: value, name: label, ...rest }) => ({
                value,
                label,
                ...rest
              })) : [];
              return accumulator;
            },
            {}
          ),
          companiesFxPairs: data.whiteLabels.reduce(
            (accumulator, currentElem) => {
              accumulator[currentElem.whitelabel.value] = currentElem.fxPairs || [];
              return accumulator;
            },
            {}
          ),
          companiesBaseCurrencies: data.whiteLabels.reduce(
            (accumulator, currentElem) => {
              accumulator[currentElem.whitelabel.value] =
                  currentElem.baseCurrencies.map((elem) => ({
                    value: elem,
                    label: elem,
                  }));
              return accumulator;
            },
            {}
          ),
          mids: data.mids.map((mid) => {
            return {
              value: mid.id,
              label: `${mid.name} (${mid.id})`,
              clients: mid.clients,
              pspId: mid.pspId,
              currency: mid.currency,
            };
          }),
          runningBalanceTypes: runningBalanceTypes,
          settlementStatuses: settlementStatuses,
          otcCashoutStatuses: otcCashoutStatuses,
          settlementByAmountStatuses: settlementStatuses.filter(
            (elem) => elem.label !== "Requested" && elem.label !== "Declined"
          ),
          settlementByRequestStatuses: settlementStatuses.filter(
            (elem) => elem.label !== "Requested" && elem.label !== "Paid"
          ),
          updateSettlementStatuses: settlementStatuses.filter(
            (elem) => elem.label !== "Requested"
          ),
          dataTypes: data.dataTypes.map((elem) => {
            return {
              value: elem.id,
              label: elem.name,
              supportedBy: elem.supportedBy
            };
          }),
          agents: agents,
          loadingLookups: false,
          currency: currency,
        });
      }
    })
      .catch((err) => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
          });
        }
      });
  }

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  }

  onConfirm = () => {
    const { showAlert, alertType } = this.state;
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: "",
    }, () => {
      if (showAlert && alertType === "success") {
        this.setState({
          showSecondPanel: false,
        });
      }
    });
  };

  handleDateChange = (event) => {
    this.setState({ date: event });
  };

  defineControlsForm = (dataType) => {
    const { controls, controlsFormRules, otcCashoutStatuses} = this.state;

    const copyArray = (arr) => {
      return JSON.parse(JSON.stringify(arr));
    };

    const runningBalanceForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      psp: "",
      midIds: "",
      updateSettlementDate: "",
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "psp",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "updateSettlementDate",
          type: "isString",
          rules: {
            required: false,
          },
        },
      ]
    ));

    const settlementByAmountForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      currency: "",
      psp: [],
      midIds: [],
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "currency",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "psp",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          },
        },
      ]
    ));

    const settlementByDateForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      date: Datetime.moment().format("DD.MM.YYYY"),
      currency: "",
      psp: [],
      midIds: [],
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "date",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "currency",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "psp",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          },
        },
      ]
    ));

    const settlementByRequestForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      currency: "",
      midIds: [],
      updateSettlementDate: "",
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "currency",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isArray",
          rules: {
            required: true,
          },
        },
        {
          name: "updateSettlementDate",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ));

    const updateSettlementForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      settlementStatus: "",
      currency: "",
      midIds: [],
      updateSettlementDate: "",
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "settlementStatus",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "currency",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isArray",
          rules: {
            required: true,
          },
        },
        {
          name: "updateSettlementDate",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ));

    const topUpPayoutWalletForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      psp: "",
      midIds: "",
      updateSettlementDate: "",
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "psp",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isNumber",
          rules: {
            required: true,
          },
        },
        {
          name: "updateSettlementDate",
          type: "isString",
          rules: {
            required: false,
          },
        },
      ]
    ));

    const withheldFundsForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      psp: "",
      midIds: "",
      updateSettlementDate: "",
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "psp",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "midIds",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "updateSettlementDate",
          type: "isString",
          rules: {
            required: false,
          },
        },
      ]
    ));

    const settleAgent = new Form({
      company: controls.company || "",
      dataType: controls.dataType || "",
      agent: controls.agent || "",
      date: this.state.timeStamp
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "date",
          type: "isDate",
          rules: {
            required: true,
          },
        },
        {
          name: "agent",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ).map(el => el.name === "merchant" ? { ...el, rules: { required: false } } : el));

    const debitCreditForm = new Form({
      company: controls.company || "",
      merchant: controls.merchant || "",
      dataType: controls.dataType || "",
      psp: controls.psp || "",
      date: Datetime.moment().format("DD.MM.YYYY"),
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "psp",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "date",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ));

    const otcCashoutForm = new Form({
      company: controls.company || "",
      dataType: controls.dataType || "OTC_CASHOUT",
      actionType: controls.dataType || "OTC_CASHOUT",
      psp: controls.psp || "",
      fxPair: "",
      accountIds: controls.accountIds || [],
      date: Datetime.moment().format("DD.MM.YYYY"),
    }, copyArray(controlsFormRules).concat(
      [
        {
          name: "actionType",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "psp",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "fxPair",
          type: "isString",
          rules: {
            required: true,
          },
        }, {
          name: "accountIds",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          },
        }, {
          name: "date",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ));

    const status = otcCashoutStatuses.find(elem => elem.value === this.collectedStatusValue)?.value;

    const otcCashoutUpdateFieldsBase = {
      company: controls.company || "",
      dataType: controls.dataType || this.otcCashoutUpdate,
      actionType: controls.dataType || this.otcCashoutUpdate,
      otcCashoutStatus: controls.otcCashoutStatus || status || "",
      currency: controls.otcCashoutStatus === this.collectedStatusValue || !controls.currency ? "" : controls.currency,
      invoice: [],
      date: "",
    };

    const otcCashoutUpdateForm = new Form(otcCashoutUpdateFieldsBase, copyArray(controlsFormRules).concat(
      [
        {
          name: "actionType",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "otcCashoutStatus",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "currency",
          type: "isString",
          rules: {
            required: true,
          },
        }, {
          name: "invoice",
          type: "isArray",
          rules: {
            min: 1,
            required: true,
          },
        },{
          name: "date",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]
    ));

    return dataType === "RUNNING_BALANCE" ? runningBalanceForm :
      dataType === "SETTLEMENT_BY_AMOUNT" ? settlementByAmountForm :
        dataType === "SETTLEMENT_BY_DATE" ? settlementByDateForm :
          dataType === "TOP_UP_PAYOUT_WALLET" ? topUpPayoutWalletForm :
            dataType === "SETTLEMENT_BY_REQUEST" ? settlementByRequestForm :
              dataType === "SETTLE_AGENT" ? settleAgent :
                dataType === "DEBIT_CREDIT" ? debitCreditForm :
                  dataType === "WITHHELD_FUNDS" ? withheldFundsForm :
                    dataType === "OTC_CASHOUT" ? otcCashoutForm :
                      dataType === this.otcCashoutUpdate ? otcCashoutUpdateForm : updateSettlementForm;
  };

  onValueChange = (event, field) => {
    const { controls, submitClicked, mids, companiesAccounts, companiesFxPairs } = this.state;
    let foundMid = [];
    if (!event) {
      controls[field] = event;
    } else if (event.target) {
      controls[field] = event.target.value;
    } else if (event.value) {
      if (field === "midIds" && (controls.dataType === "RUNNING_BALANCE" || controls.dataType === "WITHHELD_FUNDS")) {
        controls[field] = event.value.toString();
      } else {
        controls[field] = event.value;
      }
    } else {
      controls[field] = event.map((elem) => elem.value);
    }

    const pspField = controls.fieldRules.find(
      (fieldRule) => fieldRule.name === "psp"
    );
    const midIdsField = controls.fieldRules.find(
      (fieldRule) => fieldRule.name === "midIds"
    );

    if (field === "company") {
      controls.merchant = "";
      controls.psp = pspField && pspField.type === "isArray" ? [] : "";
      controls.midIds = midIdsField && midIdsField.type === "isArray" ? [] : "";
    } else if (field === "merchant") {
      controls.psp = pspField && pspField.type === "isArray" ? [] : "";
      controls.midIds = midIdsField && midIdsField.type === "isArray" ? [] : "";
    } else if (field === "psp") {
      controls.midIds = [];
      controls.midIds = midIdsField && midIdsField.type === "isArray" ? [] : "";
      controls.accountIds = [];
      controls.fxPair = "";
      this.setState({
        filteredFxPairs: this.filterAndMapFxPairs(companiesFxPairs[controls.company], controls.psp, companiesAccounts[controls.company]),
      });
    } else if (field === "currency") {
      controls.midIds = midIdsField && midIdsField.type === "isArray" ? [] : "";
      if (controls.dataType === this.otcCashoutUpdate) {
        this.loadInvoicesData();
      }
    } else if (field === "fxPair") {
      controls.accountIds = this.filterAccountsAndSelect(companiesAccounts[controls.company], controls[field], controls["psp"]).map(account => account.value);
    } else if (field === "midIds" || field === "dataType") {
      if (controls.midIds && midIdsField.type === "isArray") {
        foundMid = mids.filter((elem) => controls["midIds"].includes(elem.value));
      }
      if (foundMid) {
        this.setState({
          selectedMidCurrency: foundMid,
        });
      }
      if (field === "dataType") {
        this.setState({
          notes: ""
        });
        this.changeRequestedSettlementFieldStatus(event.value ,false);

        if (event.value === "TOP_UP_PAYOUT_WALLET") {
          this.setState({
            date: Datetime.moment()
          });
        }
      }
    }

    if (field !== "updateSettlementDate") {
      this.loadLastDate();
    }

    if (submitClicked) {
      controls.isFormValid();
    }

    this.setState({
      controls: field === "dataType" || field === "otcCashoutStatus" ? this.defineControlsForm(controls.dataType) : controls
    });
  };

  loadInvoicesData = () => {
    const { controls, date } = this.state;
    const data = {
      status: controls.otcCashoutStatus,
      currency: controls.currency,
      companyId: controls.company
    };
    crudActions.post(`v1/incoming/funds/invoices`, data).then((response) => {
      const invociesDate = Object.keys(response);
      controls.date = invociesDate[0] || "";
      controls.invoice = controls.date ? response[controls.date] : [];
      Object.keys(response).forEach(key => {
        response[key] = response[key].map(value => ({ value, label: value }));
      });
      this.setState({
        controls: controls,
        invoices: controls.date ? response[controls.date] : [],
        otcCashoutInvoiceFullInfo: response,
        otcCashoutInvoiceDates: invociesDate.map(elem => ({ value: elem, label: elem }))
      });
    }).catch((err) => {
      if (err && err.message) {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: err.message
        });
      }
    });
  };

  loadLastDate = () => {
    const { controls } = this.state;

    const dateNow = Datetime.moment();

    if (
      controls.midIds &&
      controls.midIds.length &&
      controls.merchant &&
      controls.dataType === "SETTLEMENT_BY_DATE"
    ) {
      crudActions
        .get(
          `v1/data/input/settlements?midId=${controls.midIds}&clientId=${controls.merchant}`
        )
        .then((response) => {
          if (response) {
            const lastSettlementDate = response.data
              ? Datetime.moment(response.data, "DD.MM.YYYY")
              : "";

            this.setState({
              lastSettlementDate
            });
          }
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }

          this.setState({
            lastSettlementDate: ""
          });
        });
    }

    if (
      controls.dataType === "UPDATE_SETTLEMENT" &&
      controls.midIds &&
      controls.merchant &&
      controls.settlementStatus
    ) {
      crudActions
        .get(
          `v1/data/input/dropdown-dates?midIds=${controls.midIds}&clientId=${controls.merchant}&propertyId=${controls.settlementStatus}`
        )
        .then((dates) => {
          if (dates) {
            this.setState({
              settlementDates: dates,
              controls: Object.assign(controls, {
                updateSettlementDate: dates.length ? dates[0].value : "",
              }),
            });
          }
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        });
    }

    if (
      controls.dataType === "SETTLEMENT_BY_REQUEST" &&
      controls.midIds.length &&
      controls.merchant &&
      controls.currency
    ) {
      crudActions
        .get(
          `v1/data/input/dropdown-dates/requested?midIds=${controls.midIds}&clientId=${controls.merchant}&currency=${controls.currency}`
        )
        .then((dates) => {
          if (dates) {
            this.setState({
              settlementDates: dates,
              controls: Object.assign(this.state.controls, {
                updateSettlementDate: dates.length ? dates[0].value : "",
              }),
            });
          }
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }
        });
    }

    this.setState({
      dateNow
    });
  };

  isPrecise = (num) => {
    return String(num).split(".")[1]?.length <= 2;
  };

  onCreditDebitChange = (event, field, debitCreditFormName, id) => {
    const { debitCreditForm, savedData } = this.state;
    const debitCreditFormSingle = debitCreditForm[id];

    const dynamicKey = `midsForCredit${id + 1}`;
    if (field === "debitMidId") {
      this.getMidBalance(savedData.merchant, event.value, savedData.psp, savedData.company).then(balance => {
        debitCreditFormSingle.debitBalance = balance;
        debitCreditFormSingle.creditAmount = "";
        debitCreditFormSingle[field] = event.value;
        if (debitCreditFormSingle.creditPsp) {
          this.setState({
            [dynamicKey]: this.filterMids(debitCreditFormSingle.creditPsp).filter(mid => mid.value !== debitCreditFormSingle.debitMidId),
          });
        }
        this.setState({ debitCreditForm });
      });
    } else if (field === "creditPsp") {
      debitCreditFormSingle.creditMidId = "";
      debitCreditFormSingle.creditBalance = "";
      debitCreditFormSingle[field] = event.value;
      this.setState({
        [dynamicKey]: this.filterMids(event.value).filter(mid => mid.value !== debitCreditFormSingle.debitMidId),
      });
    } else if (field === "creditMidId") {
      this.getMidBalance(savedData.merchant, event.value, savedData.psp, savedData.company).then(balance => {
        debitCreditFormSingle.creditBalance = balance;
        this.setState({ debitCreditForm });
      });
      debitCreditFormSingle[field] = event.value;
    } else if (field === "creditAmount") {

      let value = event.target.value;

      value = value.replace(/[^0-9.]/g, "");
      if (value.split('.').length > 2) {
        value = value.slice(0, value.lastIndexOf('.'));
      }
      if (parseFloat(value) <= (debitCreditFormSingle.debitBalance - this.getLockDebit(debitCreditFormSingle.debitMidId, id)) && parseFloat(value) > 0) {
        debitCreditFormSingle[field] = value;
      }
      if ( value === "") {
        debitCreditFormSingle[field] = value;
      }
    }
    this.setState({ debitCreditForm });
  };

  getLockDebit = (midId, index) => {
    const { debitCreditForm } = this.state;
    return debitCreditForm
      .filter((elem, idx) => elem.debitMidId === midId && idx !== index)
      .reduce((sum, elem) => sum + (elem.creditAmount || 0), 0);
  };

  getMidBalance = async (clientId, midId, providerId, companyId) => {
    const data = {
      clientId: clientId,
      midId: midId,
      providerId: providerId,
      companyId: companyId
    };

    try {
      const response = await crudActions.post(`v1/mids/balances`, data);
      const midInfo = response.find(info => info.midId === midId);

      if (midInfo) {
        return midInfo.balance;
      } else {
        return 0;
      }
    } catch (err) {
      if (err && err.message) {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: err.message
        });
      }
      return 0;
    }
  };

  onSettlementChange = (event, field, settlementFormName, settlementForm, id) => {
    const settlementFormOriginal = settlementForm;
    settlementForm = settlementFormName === this.formsMap.OTC_CASHOUT || settlementFormName === this.formsMap.OTC_CASHOUT_UPDATE  ? settlementFormOriginal[id] : settlementForm;

    const { controls, saveClicked, savedData, savedDataList, settlementMids } = this.state;
    if (((controls.dataType === "SETTLE_AGENT" || controls.dataType === "OTC_CASHOUT") && field === "notes")
      || (controls.dataType === "OTC_CASHOUT" && field === "confirmationLink")) {
      settlementForm[field] = event.target.value;
    } else {
      const isNumber =
      settlementForm.fieldRules.find((rule) => rule.name === field).type ===
      "isNumber";
      const numberRegex = new RegExp(/^\d+$/);
      if (!event) {
        const matchNumber = event.match(numberRegex);
        settlementForm[field] = isNumber
          ? parseInt(matchNumber ? matchNumber[0] : settlementForm[field])
          : event;
      } else if (event.target) {
        const regex = controls.dataType === "TOP_UP_PAYOUT_WALLET" ? /[^0-9\-.]/g : /[^0-9.]/g;
        const value = event.target.value.replace(regex, "");
        if (((
          controls.dataType === "SETTLEMENT_BY_AMOUNT" ||
            controls.dataType === "SETTLEMENT_BY_DATE" ||
            controls.dataType === "SETTLEMENT_BY_REQUEST") &&
          field === "requestedSettlement") ||
          (controls.dataType === "TOP_UP_PAYOUT_WALLET" &&
            field === "topUpAmount") ||
          (controls.dataType === "OTC_CASHOUT" && (field === "requestedBalance" || field === "fxRate"))
        ) {
          if (value <= settlementForm.availableBalance || field === "fxRate" || value === "-") {
            if (value.indexOf(".") > -1) {
              if (this.isPrecise(value)) {
                settlementForm[field] = value;
              }
            } else {
              settlementForm[field] = value;
            }
            if (field === "requestedBalance") {
              this.reCalculateAllRequestedBalance(settlementFormOriginal);
            }
            if(field === "requestedBalance" || field === "fxRate") {
              this.reCalculateIncomingFunds(settlementFormOriginal);
            }
          }
        } else {
          settlementForm[field] = value ? parseFloat(value) : value;
        }
      } else if (
        settlementFormName === "updateSettlementForm" &&
      field === "propertyId"
      ) {
        savedDataList.find((elem) => elem.id === id).propertyId = parseInt(event.value);
      } else if (event.value) {
        const matchNumber = event.value.match(numberRegex);
        settlementForm[field] = isNumber
          ? parseInt(matchNumber ? matchNumber[0] : settlementForm[field])
          : event.value;
      } else {
        settlementForm[field] = event.map((elem) => elem.value);
      }

      if (controls.dataType === "SETTLEMENT_BY_AMOUNT") {
        const balanceToSettleField = settlementForm.fieldRules.find(
          (fieldRule) => fieldRule.name === "balanceToSettle"
        );
        balanceToSettleField.rules.max = savedData.runningBalanceAmount;
      }

      if ((
        controls.dataType === "SETTLEMENT_BY_AMOUNT" ||
        controls.dataType === "SETTLEMENT_BY_DATE" ||
        controls.dataType === "SETTLEMENT_BY_REQUEST") &&
      field === "requestedSettlement"
      ) {
        settlementForm.balanceToSettle = 0;
        for (let i = 0; i < settlementMids.length; i++) {
          settlementMids[i].balanceToSettle = 0;
          settlementMids[i].isChecked = false;
        }
      }

      if (field === "balanceToSettle") {
        settlementForm[field] = parseInt(settlementForm[field], 10);
      }
      if (field === "fxPair") {
        settlementFormOriginal.forEach(item => {
          if (item.hasOwnProperty(field)) {
            item[field] = event.value;
          }});
      }
    }
    if (saveClicked) {
      settlementForm.isFormValid();
    }

    this.setState({
      [settlementFormName]: settlementFormOriginal,
      savedDataList: savedDataList,
      settlementMids
    });
  };

  reCalculateIncomingFunds = (cashoutForm) => {
    cashoutForm.forEach(item => {
      if (item["requestedBalance"] && item["fxRate"]) {
        item["incomingFunds"] = item["requestedBalance"] / item["fxRate"];
      } else {
        item["incomingFunds"] = "";
      }
    });
  };

  reCalculateAllRequestedBalance = (cashoutForm) => {
    const allRow = cashoutForm.find( elem => elem.hasOwnProperty("account") && elem["account"] === "All");
    if (allRow) {
      allRow["requestedBalance"] = cashoutForm.filter(elem => elem.requestedBalance && elem.account !== "All").reduce((acc, item) => acc + parseFloat(item.requestedBalance), 0);
    }
  };

  onMidRowChange = (event, field, index) => {
    const { controls, settlementMids } = this.state;
    const settlementFormName = this.formsMap[controls.dataType];
    const settlementForm = this.state[settlementFormName];

    if (field === "balanceToSettle") {
      const value = event.target.value.replace(/[^0-9.]/g, "");
      if (value <= settlementMids[index].availableBalance) {
        if (value !== "") {
          if (value.indexOf(".") > -1) {
            if (this.isPrecise(value)) {
              settlementMids[index][field] = value;
            }
          } else {
            settlementMids[index][field] = value;
          }
          settlementMids[index].isChecked = parseFloat(value) === settlementMids[index].availableBalance;
          let sumToSettle = 0;
          for (let i = 0; i < settlementMids.length; i++) {
            if (settlementMids[i].balanceToSettle) {
              sumToSettle += parseFloat(parseFloat(settlementMids[i].balanceToSettle).toFixed(2));
            }
          }
          settlementForm.balanceToSettle = parseFloat(sumToSettle.toFixed(2));
        } else {
          settlementMids[index][field] = "";
          settlementMids[index].isChecked = false;
          let sumToSettle = 0;
          for (let i = 0; i < settlementMids.length; i++) {
            if (settlementMids[i].balanceToSettle) {
              sumToSettle += parseFloat(parseFloat(settlementMids[i].balanceToSettle).toFixed(2));
            }
          }
          settlementForm.balanceToSettle = parseFloat(sumToSettle.toFixed(2));
        }

      }
      settlementForm.requestedSettlement = "";
      const midsZeroSettle = settlementMids.filter(f => f.balanceToSettle > 0);
      this.changeRequestedSettlementFieldStatus(controls.dataType, midsZeroSettle.length > 0);
    }
    this.setState({
      settlementMids
    });
  };

  onChangeFilter = () => {
    const {
      controls,
      date,
      companiesBaseCurrencies,
      companiesPsps,
      settlementFormByDate,
      settlementFormByAmount,
      settlementFormByRequest,
      settlementByAmountStatuses,
      updateSettlementForm,
      topUpPayoutWalletForm,
      selectedMidCurrency,
      mids
    } = this.state;
    const isFormValid = controls.isFormValid();

    this.setState({
      controls,
      submitClicked: true,
      lockButton: isFormValid,
      loadingDataInput: isFormValid,
    });

    const formattedDate = Datetime.moment(date).format("DD.MM.YYYY");
    
    if (isFormValid) {
      const {
        merchant,
        dataType,
        midIds,
        updateSettlementDate,
        settlementStatus,
      } = controls;

      if (dataType === 'SETTLE_AGENT') {
        this.setState({
          savedData: controls.data(),
          lockButton: false,
          showSecondPanel: true,
          loadingDataInput: false
        });
      } else if(dataType === 'DEBIT_CREDIT') {
        this.fieldsMap["DEBIT_CREDIT"] = [];
        this.setState({
          debitCreditForm: []
        });
        const data = {
          clientId: controls.merchant,
          providerId: controls.psp
        };
        crudActions.post(`v1/mids/balances`, data).then((response) => {
          const midsWithAvailableBalance = response.filter(elem => elem.balance > 0);
          midsWithAvailableBalance.forEach(elem => { this.onAddDebitCreditItem(elem.midId, elem.balance); });
          if (this.fieldsMap["DEBIT_CREDIT"].length === 0) {
            this.onAddDebitCreditItem();
          }
          this.setState({
            savedData: controls.data(),
            lockButton: false,
            showSecondPanel: true,
            loadingDataInput: false,
            midsForDebit: this.filterMids(),
            creditPsps: companiesPsps[controls.company]
          });
        }).catch((error) => {
          if (error && error.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: error.message
            });
          }

          this.setState({
            loadingDataInput: false,
            lockButton: false,
          });
        });
      } else if(dataType === "OTC_CASHOUT") {
        this.setState({
          otcCashoutForm: [],
        });
        this.fieldsMap.OTC_CASHOUT = [];
        crudActions.get(`v1/psp/accounts/balances?pspAccountIds=${controls.accountIds}&companyId=${controls.company}`).then((response) => {
          this.populateOtcCashoutForm(response);
          this.setState({
            savedData: controls.data(),
            lockButton: false,
            showSecondPanel: true,
            loadingDataInput: false,
          });
        }).catch((error) => {
          if (error && error.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: error.message
            });
          }

          this.setState({
            loadingDataInput: false,
            lockButton: false,
          });
        });
      } else if (dataType === this.otcCashoutUpdate) {
        this.onSelectInvoice();
      } else {
        this.changeRequestedSettlementFieldStatus(dataType, false);

        let url = `v1/data/input?clientId=${ merchant }&dataInputTypes=${ dataType }&midIds=${ midIds }`;
        if (controls.dataType === "RUNNING_BALANCE" || controls.dataType === "TOP_UP_PAYOUT_WALLET" || controls.dataType === "WITHHELD_FUNDS") {
          url += `&date=${ formattedDate }`;
        }
        if (controls.dataType === "SETTLEMENT_BY_DATE") {
          url += `&date=${ controls.date }`;
        }
        if (controls.dataType === "UPDATE_SETTLEMENT") {
          url += `&propertyId=${ settlementStatus }&date=${ updateSettlementDate }`;
        }
        if (controls.dataType === "SETTLEMENT_BY_REQUEST") {
          url += `&currency=${ controls.currency }&date=${ updateSettlementDate }`;
        }

        crudActions.get(url)
          .then((response) => {
            if (controls.dataType === "RUNNING_BALANCE") {
              this.setState({
                runningBalanceValues: response,
                showSecondPanel: true,
                savedData: Object.assign(controls.data(), {
                  date: formattedDate,
                  baseCurrencies:
                    companiesBaseCurrencies[controls.company] || [],
                }),
                loadingDataInput: false,
                lockButton: false,
              });
            } else if (controls.dataType === "WITHHELD_FUNDS") {
              this.setState({
                withheldFunds: response,
                showSecondPanel: true,
                savedData: Object.assign(controls.data(), {
                  date: formattedDate,
                  currency: mids && mids.find(item => item.value === Number(controls.midIds)).currency
                }),
                loadingDataInput: false,
                lockButton: false
              });
            } else if (controls.dataType === "SETTLEMENT_BY_DATE") {
              const responseMids = response.map((resp, index) => {
                return {
                  id: resp.midId,
                  name: `${ resp.name } (${ resp.midId })` || '',
                  date: resp.date || '',
                  fromDate: resp.fromDate || '',
                  toDate: resp.toDate || '',
                  pendingPayouts: resp.pendingPayouts || 0,
                  availableBalance: resp.runningBalanceAmount || 0,
                  balanceToSettle: resp.runningBalanceAmount || 0,
                  providerId: resp.providerId || '',
                  providerName: resp.providerName || '',
                  disabled: false,
                  isChecked: true
                };
              });
              if (responseMids.length) {
                let availableBalance = 0;
                for (let i = 0; i < responseMids.length; i++) {
                  const midBalance = parseFloat(responseMids[i].availableBalance);
                  availableBalance += midBalance;
                }
                this.changeRequestedSettlementFieldStatus(controls.dataType, true);
                this.setState({
                  settlementFormByDate: Object.assign(settlementFormByDate, {
                    availableBalance: parseFloat((availableBalance).toFixed(2)) || 0,
                    propertyId: "17",
                    currency: controls.currency || "",
                    requestedSettlement: availableBalance.toFixed(2).toString() || 0,
                    balanceToSettle: parseFloat((availableBalance).toFixed(2)) || 0
                  }),
                  showSecondPanel: true,
                  savedData: Object.assign(controls.data(), {
                    date: formattedDate,
                    baseCurrencies: companiesBaseCurrencies[controls.company] || [],
                    currency: "",
                  }),
                  settlementMids: responseMids,
                  loadingDataInput: false,
                  lockButton: false
                });
              } else {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: "The selected MIDs has no available balance.",
                  showSecondPanel: false,
                  loadingDataInput: false,
                  lockButton: false
                });
              }
            } else if (controls.dataType === "SETTLEMENT_BY_AMOUNT") {
              const responseMids = response.map((resp, index) => {
                return {
                  id: resp.id,
                  name: `${ resp.name } (${ resp.id })` || '',
                  date: resp.date || '',
                  pendingPayouts: resp.pendingPayouts || 0,
                  availableBalance: resp.availableBalance || 0,
                  payoutWalletBalance: resp.walletBalance || 0,
                  payoutWalletType: resp.payoutWalletType || "",
                  balanceToSettle: 0,
                  providerName: resp.providerName || '',
                  withheldFunds: resp.withheldFunds || 0,
                  disabled: false,
                  isChecked: false
                };
              });
              if (responseMids.length) {
                let availableBalance = 0;
                for (let i = 0; i < responseMids.length; i++) {
                  const midBalance = parseFloat(responseMids[i].availableBalance);
                  availableBalance += midBalance;
                }
                this.setState({
                  settlementFormByAmount: Object.assign(settlementFormByAmount, {
                    availableBalance: parseFloat((availableBalance).toFixed(2)) || 0,
                    propertyId: "17",
                    currency: controls.currency || "",
                    requestedSettlement: "",
                    balanceToSettle: 0
                  }),
                  showSecondPanel: true,
                  savedData: Object.assign(controls.data(), {
                    date: formattedDate,
                    baseCurrencies: companiesBaseCurrencies[controls.company] || [],
                    midCurrency: selectedMidCurrency,
                    currency: "",
                    settlementByAmountStatuses: settlementByAmountStatuses,
                  }),
                  settlementMids: responseMids,
                  loadingDataInput: false,
                  lockButton: false,
                  notes: ""
                });
              } else {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: "The selected MIDs has no available balance.",
                  showSecondPanel: false,
                  loadingDataInput: false,
                  lockButton: false
                });
              }
            } else if (controls.dataType === "SETTLEMENT_BY_REQUEST") {
              crudActions.get(`v1/data/input?clientId=${ merchant }&dataInputTypes=SETTLEMENT_BY_AMOUNT&midIds=${ midIds }&date=${ updateSettlementDate }&currency=${ controls.currency }`)
                .then((midsResponse) => {
                  const responseMids = midsResponse.map((resp, index) => {
                    return {
                      id: resp.id,
                      name: `${ resp.name } (${ resp.id })` || '',
                      payoutWalletBalance: resp.walletBalance || 0,
                      payoutWalletType: resp.payoutWalletType || "",
                      pendingPayouts: resp.pendingPayouts || 0,
                      availableBalance: resp.availableBalance || 0,
                      balanceToSettle: 0,
                      providerName: resp.providerName || '',
                      disabled: false,
                      isChecked: false
                    };
                  });
                  if (responseMids.length) {
                    let availableBalance = 0;
                    for (let i = 0; i < midsResponse.length; i++) {
                      const midBalance = parseFloat(midsResponse[i].availableBalance);
                      availableBalance += midBalance;
                    }
                    this.setState({
                      settlementFormByRequest: Object.assign(settlementFormByRequest, {
                        availableBalance: parseFloat((availableBalance).toFixed(2)),
                        balanceToSettle: 0,
                        propertyId: "17",
                        requestedSettlement: parseFloat(response.balanceToSettle).toFixed() || 0,
                        currency: controls.currency || "",
                      }),
                      showSecondPanel: true,
                      savedData: Object.assign(controls.data(), {
                        settlementRequestId: response.id,
                        date: formattedDate,
                        baseCurrencies: companiesBaseCurrencies[controls.company] || [],
                        midCurrency: selectedMidCurrency,
                        currency: "",
                      }),
                      loadingDataInput: false,
                      lockButton: false,
                      settlementMids: responseMids,
                      notes: ""
                    });
                  } else {
                    this.setState({
                      showAlert: true,
                      alertType: "error",
                      alertMessage: "There is no available balance on the selected MIDs.",
                      showSecondPanel: false,
                      loadingDataInput: false,
                      lockButton: false
                    });
                  }
                }).catch((err) => {
                  if (err && err.message) {
                    this.setState({
                      showAlert: true,
                      alertType: "error",
                      alertMessage: err.message
                    });
                  }

                  this.setState({
                    loadingDataInput: false,
                    lockButton: false,
                  });
                });
            } else if (controls.dataType === "UPDATE_SETTLEMENT") {
              this.setState({
                updateSettlementForm: Object.assign(updateSettlementForm, {
                  propertyId: response.propertyId
                    ? response.propertyId.toString()
                    : "",
                }),
                showSecondPanel: true,
                savedData: Object.assign(controls.data()),
                savedDataList: response.map((resp) => {
                  return {
                    date: resp.date,
                    fromDate: resp.fromDate,
                    toDate: resp.toDate,
                    id: resp.id,
                    name: resp.name,
                    midId: resp.midId,
                    propertyId: resp.propertyId,
                    clientId: resp.clientId,
                    balanceToSettle: resp.balanceToSettle,
                    runningBalanceAmount: resp.runningBalanceAmount,
                  };
                }),

                showDates: !!(response.fromDate || response.toDate),
                loadingDataInput: false,
                lockButton: false,
              });
            } else if (controls.dataType === "TOP_UP_PAYOUT_WALLET") {
              this.setState({
                topUpPayoutWalletForm: Object.assign(topUpPayoutWalletForm, {
                  midCurrency: response.currency || "",
                  availableBalance: response.availableBalanceAmount || 0,
                  topUpAmount: ""
                }),
                savedData: Object.assign(controls.data(), {
                  topUpDate: response.date || "",
                  merchantId: response.merchantId || undefined,
                  midId: response.midId || undefined,
                }),
                showSecondPanel: true,
                loadingDataInput: false,
                lockButton: false,
              });
            }
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message
              });
            }

            this.setState({
              loadingDataInput: false,
              lockButton: false
            });
          });
      }
    }
  };

  onSelectInvoice = () => {
    const { controls } = this.state;
    crudActions.get(`v1/incoming/funds?invoice=${controls.invoice}&companyId=${controls.company}`).then((response) => {
      this.populateOtcCashoutUpdateForm(response);
      this.setState({
        savedData: controls.data(),
        lockButton: false,
        chooseOtcInvoice: false,
        invoiceDataReceived: true,
        loadingDataInput: false,
        showSecondPanel: true,
      });
    });
  };

  populateOtcCashoutUpdateForm = (response) => {
    const { controls, companiesAccounts } = this.state;
    this.fieldsMap.OTC_CASHOUT_UPDATE = [];
    this.setState({
      otcCashoutUpdateForm: []
    });
    response.forEach(invoiceInfo => {
      const incomingFunds = invoiceInfo.incomingFunds.map(() => [
        {
          name: "account",
          label: "Account",
          fieldType: "input",
          disabled: true,
          flexGrow: "10"
        }, {
          name: "fxRate",
          label: "FX rate",
          fieldType: "input",
          disabled: true
        }, {
          name: "requestedBalance",
          label: "Requested Balance",
          fieldType: "input",
          disabled: true,
          flexGrow: "10"
        },
        {
          name: "incomingFunds",
          label: "Inbound Settlement",
          fieldType: "input",
          disabled: true,
          flexGrow: "10"
        }]);

      this.fieldsMap.OTC_CASHOUT_UPDATE = this.fieldsMap.OTC_CASHOUT_UPDATE.concat(incomingFunds);
      this.fieldsMap.OTC_CASHOUT_UPDATE.push([
        {
          name: "invoice",
          label: "Invoice",
          fieldType: "input",
          disabled: true
        },
        {
          name: "currency",
          label: "Currency",
          fieldType: "input",
          disabled: true
        },
        {
          name: "fxPair",
          label: "FX Pair",
          fieldType: "input",
          disabled: true
        },
        {
          name: "status",
          label: "Status",
          fieldType: "select",
          items: "otcCashoutStatuses"
        }, {}, {}
      ]);
      this.fieldsMap.OTC_CASHOUT_UPDATE.push([]);

      invoiceInfo.incomingFunds.forEach(incomingFund => {
        this.setState((prevState) => ({
          otcCashoutUpdateForm: [...prevState.otcCashoutUpdateForm, new Form(
            {
              account: companiesAccounts[controls.company].find(item => item.value === Number(incomingFund.accountId)).label,
              fxRate: incomingFund.fxRate,
              requestedBalance: incomingFund.requestedBalance,
              incomingFunds: incomingFund.incomingFundsAmount
            },
            [
              {
                name: "account",
                type: "isString",
                rules: {
                  required: true
                },
              },
              {
                name: "fxRate",
                type: "isNumber",
                rules: {
                  required: true,
                },
              },
              {
                name: "requestedBalance",
                type: "isNumber",
                rules: {
                  required: true,
                },
              },
              {
                name: "incomingFunds",
                type: "isNumber",
                rules: {
                  required: true,
                },
              }
            ]
          )],
        }));
      });

      const otcCashoutUpdateFormState = new Form({
        invoice: invoiceInfo.invoice,
        currency: invoiceInfo.currency,
        fxPair: invoiceInfo.fxPair,
        status: controls.otcCashoutStatus,
      },
      [
        {
          name: "currency",
          type: "isString",
          rules: {
            required: true
          },
        },
        {
          name: "fxPair",
          type: "isString",
          rules: {
            required: true,
          },
        },
        {
          name: "status",
          type: "isString",
          rules: {
            required: true,
          },
        },
      ]);

      this.setState((prevState) => ({otcCashoutUpdateForm: [...prevState.otcCashoutUpdateForm, otcCashoutUpdateFormState]}));
      this.setState((prevState) => ({otcCashoutUpdateForm: [...prevState.otcCashoutUpdateForm, new Form()]}));
    });
  };

  populateOtcCashoutForm = (accounts) => {
    const { controls, companiesAccounts, otcCashoutStatuses } = this.state;
    let { otcCashoutFormState } = this.state;
    this.fieldsMap.OTC_CASHOUT = [[
      {
        name: "confirmationLink",
        label: "Confirmation Link",
        fieldType: "input",
        flexGrow: "10",
      },
      {
        name: "status",
        label: "Status",
        fieldType: "select",
        items: "otcCashoutStatuses",
      },
      {
        name: "notes",
        label: "Notes",
        fieldType: "textarea",
        flexGrow: "10",
      }, {}, {}
    ]];
    const otcAccounts = accounts.map((account) => [
      {
        name: "account",
        label: "Account",
        fieldType: "input",
        disabled: true,
        flexGrow: "10"
      }, {
        name: "currency",
        label: "Currency",
        fieldType: "input",
        disabled: true
      }, {
        name: "availableBalance",
        label: "Available Balance",
        fieldType: "input",
        disabled: true,
        flexGrow: "10"
      },
      {
        name: "requestedBalance",
        label: "Requested Balance",
        fieldType: "input",
        disabled: Number(account.balance) <= 0,
        flexGrow: "10"
      }, {
        name: "fxRate",
        label: "FX Rate",
        disabled: Number(account.balance) <= 0,
        fieldType: "input"
      }, {
        name: "fxPair",
        label: "FX Pair",
        fieldType: "input",
        disabled: true
      }, {
        name: "incomingFunds",
        label: "Inbound Settlement",
        fieldType: "input",
        flexGrow: "10",
        disabled: true,
      },{
        name: "checkBox",
        fieldType: "checkBox",
        disabledField: "availableBalance",
        enteredField: "requestedBalance",
        autoMethod: (index) => this.onAutoCashout(index),
        disabled: true,
        flexGrow: "0"
      }]);

    if (accounts.length > 1) {
      otcAccounts.push([
        {
          name: "account",
          label: "",
          fieldType: "input",
          disabled: true,
          flexGrow: "10"
        }, {
          name: "currency",
          label: "Currency",
          fieldType: "input",
          disabled: true
        }, {
          name: "availableBalance",
          label: "Total Available Balance",
          fieldType: "input",
          disabled: true,
          flexGrow: "10"
        }, {
          name: "requestedBalance",
          label: "Requested Balance",
          fieldType: "input",
          disabled: true,
          flexGrow: "10"
        }, {
          name: "fxRate",
          label: "FX Rate",
          fieldType: "input",
          disabled: true
        }, {
          name: "fxPair",
          label: "FX Pair",
          fieldType: "input",
          disabled: true
        }, {
          name: "incomingFunds",
          label: "Inbound Settlement",
          fieldType: "input",
          disabled: true,
          flexGrow: "10"
        }, {
          name: "checkBox",
          fieldType: "checkBox",
          empty: true,
          flexGrow: "0"
        }]);
    }
    this.fieldsMap.OTC_CASHOUT = otcAccounts.concat(this.fieldsMap.OTC_CASHOUT);

    accounts.forEach(account => {
      this.setState((prevState) => ({
        otcCashoutForm: [...prevState.otcCashoutForm, new Form(
          {
            account: companiesAccounts[controls.company].find(item => item.value === Number(account.pspAccountId)).label,
            currency: controls.fxPair.split("/")[1],
            availableBalance: account.balance,
            requestedBalance: "",
            fxRate: "",
            fxPair: this.getFxPairValue(controls.fxPair),
            incomingFunds: ""
          },
          [
            {
              name: "account",
              type: "isString",
              rules: {
                required: true
              },
            },
            {
              name: "currency",
              type: "isString",
            },
            {
              name: "availableBalance",
              type: "isNumber",
              rules: {
                required: true,
              },
            },
            {
              name: "requestedBalance",
              type: "isNumber",
              rules: {
                required: Number(account.balance) > 0 ,
              },
            },
            {
              name: "fxRate",
              type: "isNumber",
              rules: {
                required: Number(account.balance) > 0,
              },
            },
            {
              name: "fxPair",
              type: "isString",
              rules: {
                required: true,
              },
            },
            {
              name: "incomingFunds",
              type: "isNumber",
              rules: {
                required: Number(account.balance) > 0,
              },
            }
          ]
        )],
      }));
    });

    if(accounts.length > 1) {
      this.setState((prevState) => ({
        otcCashoutForm: [...prevState.otcCashoutForm, new Form(
          {
            account: "All",
            currency: controls.fxPair.split("/")[1],
            availableBalance: accounts.reduce((acc, item) => acc + item.balance, 0),
            requestedBalance: "",
            fxRate: "",
            fxPair: this.getFxPairValue(controls.fxPair),
            incomingFunds: ""
          },
          [
            {
              name: "account",
              type: "isString",
              rules: {
                required: true
              },
            },
            {
              name: "currency",
              type: "isString",
            },
            {
              name: "totalAvailableBalance",
              type: "isNumber",
              rules: {
                required: true,
              },
            },
            {
              name: "requestedBalance",
              type: "isNumber",
              rules: {
                required: accounts.reduce((acc, item) => acc + item.balance, 0) > 0,
              },
            },
            {
              name: "fxRate",
              type: "isNumber",
              rules: {
                required: accounts.reduce((acc, item) => acc + item.balance, 0) > 0,
              },
            },
            {
              name: "fxPair",
              type: "isString",
              rules: {
                required: true,
              },
            },
            {
              name: "incomingFunds",
              type: "isNumber",
              rules: {
                required: accounts.reduce((acc, item) => acc + item.balance, 0) > 0,
              },
            }
          ]
        )],
      }));
    }

    const status = otcCashoutStatuses.find(elem => elem.value === this.collectedStatusValue)?.value;
    otcCashoutFormState = new Form(
      {
        confirmationLink: "",
        status: status || "",
        notes: ""
      },
      [
        {
          name: "confirmationLink",
          type: "isUrl",
          rules: {
            required: true,
          },
        },
        {
          name: "status",
          type: "isString",
          rules: {
            required: true,
          }
        },
        {
          name: "notes",
          type: "isString"
        }
      ]
    );
    this.setState((prevState) => ({otcCashoutForm: [...prevState.otcCashoutForm, otcCashoutFormState]}));
    this.setState(({otcCashoutFormState: otcCashoutFormState}));
  };

  getFxPairValue = (fxPair) => {
    return fxPair.split("/")[0] === fxPair.split("/")[1] ? fxPair.split("/")[1] : fxPair;
  };

  onMidSelect = (index, dataType) => {
    const settlementFormName = this.formsMap[dataType];
    const settlementForm = this.state[settlementFormName];
    const { settlementMids } = this.state;
    const checkedState = settlementMids[index].isChecked;
    settlementMids[index].isChecked = !checkedState;

    if (settlementMids[index].isChecked) {
      if (settlementForm.requestedSettlement !== "") {
        if (
          parseFloat(settlementForm.balanceToSettle.toFixed(2)) < parseFloat(parseFloat(settlementForm.requestedSettlement).toFixed(2))
        ) {
          const diff = parseFloat(parseFloat(settlementForm.requestedSettlement).toFixed(2)) - parseFloat(settlementForm.balanceToSettle.toFixed(2));
          if (diff > settlementMids[index].availableBalance) {
            settlementMids[index].balanceToSettle = parseFloat(settlementMids[index].availableBalance.toFixed(2));
            settlementForm.balanceToSettle = parseFloat(settlementForm.balanceToSettle.toFixed(2)) + parseFloat(settlementMids[index].availableBalance.toFixed(2));
          } else {
            const balanceDiff = Math.abs(
              parseFloat(settlementForm.balanceToSettle.toFixed(2)) -
                parseFloat(parseFloat(settlementForm.requestedSettlement).toFixed(2))
            );
            settlementMids[index].balanceToSettle = parseFloat(balanceDiff.toFixed(2));
            settlementForm.balanceToSettle = parseFloat(settlementForm.balanceToSettle.toFixed(2)) + parseFloat(balanceDiff.toFixed(2));
            for (let i = 0; i < settlementMids.length; i++) {
              settlementMids[i].disabled = settlementMids[i].balanceToSettle === 0;
            }
          }
        } else {
          for (let i = 0; i < settlementMids.length; i++) {
            settlementMids[i].disabled = settlementMids[i].balanceToSettle === 0;
          }
        }
      } else {
        settlementMids[index].balanceToSettle = parseFloat(settlementMids[index].availableBalance.toFixed(2));
        let sumToSettle = 0;
        for (let i = 0; i < settlementMids.length; i++) {
          sumToSettle += parseFloat(parseFloat(settlementMids[i].balanceToSettle).toFixed(2));
        }
        settlementForm.balanceToSettle = parseFloat(sumToSettle.toFixed(2));
      }
      this.changeRequestedSettlementFieldStatus(dataType, true);
    } else {
      settlementMids[index].balanceToSettle = 0;
      let sumToSettle = 0;
      for (let i = 0; i < settlementMids.length; i++) {
        settlementMids[i].disabled = false;
        sumToSettle += parseFloat(parseFloat(settlementMids[i].balanceToSettle).toFixed(2));
      }
      settlementForm.balanceToSettle = parseFloat(sumToSettle.toFixed(2));
      if (settlementForm.balanceToSettle === 0 && dataType !== "SETTLEMENT_BY_DATE") {
        this.changeRequestedSettlementFieldStatus(dataType, false);
      }
    }

    this.setState({ settlementMids, [settlementFormName]: settlementForm });
  };

  changeRequestedSettlementFieldStatus = (dataType, status) => {
    const fields = this.fieldsMap[dataType];
    if (dataType === "SETTLEMENT_BY_AMOUNT" || dataType === "SETTLEMENT_BY_REQUEST") {
      const fieldIndex = fields[0].findIndex(item => item.name === "requestedSettlement");
      fields[0][fieldIndex].disabled = status;
    }
  };

  filterMids = (psp) => {
    const { controls, mids } = this.state;
    if (!psp && (!controls.psp || !controls.merchant)) {
      return [];
    }
    psp = !psp ? controls.psp : psp;

    const filteredMidsByMerchant = mids.filter(
      (mid) =>
        mid.clients.some(
          (client) => client.clientId === parseInt(controls.merchant, 10)
        )
    );

    const filteredMids = filteredMidsByMerchant.filter(
      (mid) => typeof psp !== "string" ?
        psp.includes(mid.pspId.toString()) :
        psp === mid.pspId.toString()
    );

    return filteredMids;
  };

  filterMidsByCurrency = () => {
    const { controls, mids } = this.state;
    if (!controls.merchant) {
      return [];
    }
    return mids.filter(
      (mid) =>
        mid.clients.some(
          (client) => client.clientId === parseInt(controls.merchant, 10)
        ) && mid.currency === controls.currency
    );
  };

  onTypesTemporaryChange = (temporaryItem) => {
    this.setState({
      temporaryRunningBalanceItem: temporaryItem
    });
  };

  onTypesChange = (selectedItems) => {
    if (selectedItems) {
      this.setState({
        runningBalanceValues: selectedItems,
      });
    }
  };

  onWithheldTemporaryChange = (temporaryItem) => {
    this.setState({
      temporaryWithheldItem: temporaryItem
    });
  };

  onWithheldChange = (funds) => {
    this.setState({
      withheldFunds: funds
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(
      (elem) => elem.permission === permissionName
    );
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  mapSelectedItems = (type, key, obj) => {
    const field = this.state.controls[type];
    const selected = this.state[obj];
    const lookup = selected[key];

    return field.map((elem) => {
      return lookup.find((lookupElem) => elem === lookupElem.value || elem === lookupElem.id);
    });
  };

  mapSelectedMids = () => {
    const { controls, mids } = this.state;
    return controls.midIds
      ? mids.filter((elem) => controls.midIds.indexOf(elem.value) > -1)
      : [];
  };

  onSaveDataEntries = () => {
    const {
      savedData,
      runningBalanceValues,
      temporaryRunningBalanceItem,
      settlementFormByDate,
      settlementFormByAmount,
      settlementFormByRequest,
      controls,
      updateSettlementForm,
      savedDataList,
      settlementMids,
      topUpPayoutWalletForm,
      settleAgentForm,
      debitCreditForm,
      otcCashoutForm,
      otcCashoutUpdateForm,
      otcCashoutFormState,
      companiesAccounts,
      notes,
      temporaryWithheldItem,
      withheldFunds
    } = this.state;

    if (savedData.dataType === "RUNNING_BALANCE") {
      if (temporaryRunningBalanceItem.id && temporaryRunningBalanceItem.value) {
        runningBalanceValues.push(temporaryRunningBalanceItem);
      }

      this.setState({
        lockButton: true,
        loadingDataInput: true
      });

      crudActions
        .put(`v1/data/input`, {
          data: runningBalanceValues,
          clientId: savedData.merchant,
          midId: savedData.midIds,
          date: savedData.date,
        })
        .then(() => {
          this.setState({
            controls: Object.assign(controls, {
              merchant: null,
              psp: null,
              midIds: null,
              dataType: null,
            }),
            temporaryRunningBalanceItem: {},
            showAlert: true,
            alertType: "success",
            alertMessage: "Settlement successfully initiated.",
            lockButton: false,
            loadingDataInput: false
          });
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
            });
          }

          this.setState({
            lockButton: false,
            loadingDataInput: false
          });
        });
    } else if (savedData.dataType === "WITHHELD_FUNDS") {
      if (temporaryWithheldItem.amount) {
        withheldFunds.push(temporaryWithheldItem);
        this.setState({
          temporaryWithheldItem: {}
        });
      }

      if (withheldFunds) {
        withheldFunds.forEach(fund => {
          if (!fund.clientId) {
            fund.clientId = Number(savedData.merchant);
          }
          if (!fund.midId) {
            fund.midId = Number(savedData.midIds);
          }
        });
      }

      this.setState({
        lockButton: true,
        loadingDataInput: true
      });

      crudActions.post(`v1/data/input/withheld-funds`, withheldFunds)
        .then(() => {
          this.setState({
            controls: Object.assign(controls, {
              merchant: null,
              psp: null,
              midIds: null,
              dataType: null,
            }),
            showAlert: true,
            alertType: "success",
            alertMessage: "Operation successfull.",
            lockButton: false,
            loadingDataInput: false
          });
        })
        .catch((err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message
            });
          }

          this.setState({
            lockButton: false,
            loadingDataInput: false
          });
        });
    } else if (savedData.dataType === "SETTLEMENT_BY_DATE") {
      const isSettlementFormValid = settlementFormByDate.isFormValid();

      this.setState({
        saveClicked: true,
        settlementFormByDate,
        lockButton: isSettlementFormValid,
        loadingDataInput: isSettlementFormValid
      });

      if (isSettlementFormValid) {
        if (settlementFormByDate.balanceToSettle > 0) {
          const data = settlementMids.filter(m => m.isChecked && m.balanceToSettle > 0).map((mid) => {
            return {
              id: mid.id,
              name: mid.name,
              pendingPayouts: mid.pendingPayouts,
              runningBalanceAmount: mid.availableBalance,
              balanceToSettle: mid.balanceToSettle,
              currency: settlementFormByDate.currency,
              date: mid.date,
              fromDate: mid.fromDate,
              toDate: mid.toDate,
              midId: mid.id,
              providerId: mid.providerId,
              providerName: mid.providerName,
              clientId: savedData.merchant,
              propertyId: settlementFormByDate.propertyId
            };
          });

          crudActions
            .post(
              `v1/data/input/settlements`,
              data
            )
            .then(() => {
              this.setState({
                controls: Object.assign(controls, {
                  merchant: null,
                  psp: null,
                  midIds: null,
                  dataType: null,
                }),
                showAlert: true,
                alertType: "success",
                alertMessage: "Settlement successfully initiated.",
                lockButton: false,
                loadingDataInput: false
              });
            })
            .catch((err) => {
              if (err && err.message) {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: err.message
                });
              }

              this.setState({
                lockButton: false,
                loadingDataInput: false
              });
            });
        } else {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: 'Balance to Settle value should be greater than 0! Please, select at least one MID to proceed.',
            lockButton: false,
            loadingDataInput: false
          });
        }
      }
    } else if (savedData.dataType === "SETTLEMENT_BY_AMOUNT") {
      const isSettlementFormValid = settlementFormByAmount.isFormValid();
      this.setState({
        saveClicked: true,
        settlementFormByAmount,
        lockButton: isSettlementFormValid,
        loadingDataInput: isSettlementFormValid && settlementFormByAmount.balanceToSettle > 0
      });

      if (isSettlementFormValid) {
        if (settlementFormByAmount.balanceToSettle > 0) {
          const data = settlementMids.filter(m => parseFloat(m.balanceToSettle) > 0).map((mid) => {
            return {
              id: mid.id,
              name: mid.name,
              walletBalance: mid.payoutWalletBalance,
              pendingPayouts: mid.pendingPayouts,
              availableBalance: mid.availableBalance,
              balanceToSettle: mid.balanceToSettle,
              currency: settlementFormByAmount.currency,
              date: mid.date,
              midId: mid.id,
              clientId: savedData.merchant,
              propertyId: settlementFormByAmount.propertyId,
              notes
            };
          });

          crudActions
            .post(
              `v1/data/input/settlements/amount`,
              data
            )
            .then(() => {
              this.setState({
                controls: Object.assign(controls, {
                  merchant: null,
                  psp: null,
                  midIds: null,
                  dataType: null,
                }),
                showAlert: true,
                alertType: "success",
                alertMessage: "Settlement successfully initiated.",
                lockButton: false,
                loadingDataInput: false,
                notes: ""
              });
            })
            .catch((err) => {
              if (err && err.message) {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: err.message,
                });
              }

              this.setState({
                lockButton: false,
                loadingDataInput: false
              });
            });
        } else {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: 'Balance to Settle value should be greater than 0! Please, select at least one MID to proceed.',
            lockButton: false,
            loadingDataInput: false
          });
        }
          
      }
    } else if (savedData.dataType === "SETTLEMENT_BY_REQUEST") {
      const isSettlementFormValid = settlementFormByRequest.isFormValid();
      this.setState({
        saveClicked: true,
        settlementFormByRequest,
        lockButton: isSettlementFormValid,
        loadingDataInput: isSettlementFormValid && settlementFormByRequest.balanceToSettle > 0
      });

      if (isSettlementFormValid) {
        if (settlementFormByRequest.balanceToSettle > 0 || settlementFormByRequest.propertyId === "19") {
          const data = settlementMids.filter(m => (m.isChecked && m.balanceToSettle > 0 && settlementFormByRequest.propertyId !== "19") || (m.isChecked && settlementFormByRequest.propertyId === "19")).map((mid) => {
            return {
              id: mid.id,
              name: mid.name,
              walletBalance: mid.payoutWalletBalance,
              pendingPayouts: mid.pendingPayouts,
              availableBalance: mid.availableBalance,
              balanceToSettle: mid.balanceToSettle,
              currency: settlementFormByRequest.currency,
              date: savedData.date,
              midId: mid.id,
              clientId: savedData.merchant,
              propertyId: settlementFormByRequest.propertyId,
              notes
            };
          });
          if (!data.length) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: 'Please select a mid - even if you intend to decline a settlement.',
              lockButton: false,
              loadingDataInput: false
            });
            return;
          }
          crudActions
            .post(
              `v1/data/input/settlements/submit`,
              {
                settlements: data,
                id: savedData.settlementRequestId,
                amount: settlementFormByRequest.requestedSettlement,
                propertyId: settlementFormByRequest.propertyId
              }
            )
            .then(() => {
              this.setState({
                controls: Object.assign(controls, {
                  merchant: null,
                  psp: null,
                  midIds: null,
                  dataType: null,
                }),
                showAlert: true,
                alertType: "success",
                alertMessage: settlementFormByRequest.propertyId === "19" ? "Settlement declined." : "Settlement successfully initiated.",
                lockButton: false,
                loadingDataInput: false,
                notes: ""
              });
            })
            .catch((err) => {
              if (err && err.message) {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: err.message
                });
              }

              this.setState({
                lockButton: false,
                loadingDataInput: false
              });
            });
        } else {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: 'Please select a mid - even if you intend to decline a settlement.',
            lockButton: false,
            loadingDataInput: false
          });
        }
      }
    } else if (savedData.dataType === "UPDATE_SETTLEMENT") {
      const isSettlementFormValid = updateSettlementForm.isFormValid();
      this.setState({
        saveClicked: true,
        updateSettlementForm,
        lockButton: isSettlementFormValid,
        loadingDataInput: isSettlementFormValid
      });

      if (isSettlementFormValid) {
        crudActions
          .put(
            `v1/data/input/settlements`,
            savedDataList,
          )
          .then(() => {
            this.setState({
              controls: Object.assign(controls, {
                merchant: null,
                psp: null,
                midIds: null,
                dataType: null,
              }),
              showAlert: true,
              alertType: "success",
              alertMessage: "Operation successfull.",
              lockButton: false,
              loadingDataInput: false
            });
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message
              });
            }

            this.setState({
              lockButton: false,
              loadingDataInput: false
            });
          });
      }
    } else if (savedData.dataType === "TOP_UP_PAYOUT_WALLET") {
      const isTopUpPayoutWalletFormValid = topUpPayoutWalletForm.isFormValid();
      this.setState({
        saveClicked: true,
        topUpPayoutWalletForm,
        lockButton: isTopUpPayoutWalletFormValid,
        loadingDataInput: isTopUpPayoutWalletFormValid
      });

      if (isTopUpPayoutWalletFormValid) {
        const data = {
          merchantId: savedData.merchantId,
          midId: savedData.midId,
          date: savedData.topUpDate,
          topUpAmount: parseFloat(topUpPayoutWalletForm.topUpAmount, 2),
          availableBalanceAmount: topUpPayoutWalletForm.availableBalance,
          currency: topUpPayoutWalletForm.midCurrency,
        };

        crudActions
          .post(
            `v1/data/input/payout/top-up`,
            data,
          )
          .then(() => {
            this.setState({
              controls: Object.assign(controls, {
                merchant: null,
                psp: null,
                midIds: null,
                dataType: null,
              }),
              savedData: {},
              showAlert: true,
              alertType: "success",
              alertMessage: "Operation successfull.",
              lockButton: false,
              loadingDataInput: false
            });
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
              });
            }

            this.setState({
              lockButton: false,
              loadingDataInput: false
            });
          });
      }
    } else if (savedData.dataType === "SETTLE_AGENT") {
      const isSettleAgentFormValid = settleAgentForm.isFormValid();
      this.setState({
        saveClicked: true,
        settleAgentForm,
        lockButton: isSettleAgentFormValid,
        loadingDataInput: isSettleAgentFormValid
      });

      if (isSettleAgentFormValid) {
        const data = {
          date: savedData.date.format("DD.MM.yyyy HH:mm"),
          value: settleAgentForm.amount,
          agentId: savedData.agent,
          notes: settleAgentForm.notes,
          currency: "USD",
        };

        crudActions
          .post(
            `v1/settlement/agent`,
            data,
          )
          .then(() => {
            this.setState({
              controls: Object.assign(controls, {
                merchant: null,
                psp: null,
                midIds: null,
                dataType: null,
              }),
              settleAgentForm: Object.assign(settleAgentForm, {
                amount: "",
                notes: ""
              }),
              savedData: {},
              showAlert: true,
              alertType: "success",
              alertMessage: "Operation successfull.",
              lockButton: false,
              loadingDataInput: false
            });
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
              });
            }

            this.setState({
              lockButton: false,
              loadingDataInput: false
            });
          });
      }
    } else if (this.isCreditDebit()) {
      debitCreditForm.map(elem => elem.creditAmount = elem.creditAmount ? parseFloat(elem.creditAmount) : elem.creditAmount);
      const isDebitCreditFormValid = debitCreditForm.every((form) => form.isFormValid());
      this.setState({
        saveClicked: true,
        debitCreditForm,
        lockButton: isDebitCreditFormValid,
        loadingDataInput: isDebitCreditFormValid
      });

      if (isDebitCreditFormValid) {
        const credits = debitCreditForm.map((credit) => ({
          fromMidId: credit.debitMidId,
          toMidId: credit.creditMidId,
          value: credit.creditAmount,
        }));
        const data = {
          date: savedData.date,
          clientId: savedData.merchant,
          data: credits
        };

        crudActions
          .post(
            `v1/data/input/debit_credit`,
            data,
          )
          .then(() => {
            debitCreditForm.forEach((elem, index) => {
              this.setState({
                [`midsForCredit${index + 1}`]: []
              });
            });
            this.setState({
              savedData: {},
              showAlert: true,
              alertType: "success",
              alertMessage: "Operation successfull.",
              lockButton: false,
              loadingDataInput: false
            });
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
              });
            }

            this.setState({
              lockButton: false,
              loadingDataInput: false
            });
          });
      }
    } else if (savedData.dataType === "OTC_CASHOUT") {
      otcCashoutForm.map(elem => elem.fxRate = elem.fxRate ? parseFloat(elem.fxRate) : elem.fxRate);
      otcCashoutForm.map(elem => elem.requestedBalance = elem.requestedBalance ? parseFloat(elem.requestedBalance) : elem.requestedBalance);
      const otcCashoutFormToSettle = otcCashoutForm.filter(elem => elem.availableBalance > 0 || "status" in elem);
      const otcCashoutFormToSettleRequested = otcCashoutForm.filter(elem => elem.requestedBalance > 0 || "status" in elem);

      const isOtcCashoutFormValid = otcCashoutFormToSettle.every((form) => form.isFormValid());
      this.setState({
        otcCashoutForm,
        lockButton: isOtcCashoutFormValid,
      });
      if (isOtcCashoutFormValid && (otcCashoutFormToSettle.length === 1 || otcCashoutFormToSettle.length !== otcCashoutFormToSettleRequested.length)) {
        this.setState({
          showAlert: true,
          alertType: "error",
          alertMessage: "Nothing to settle",
          lockButton: false
        });
        return;
      }

      if (isOtcCashoutFormValid) {
        const incomingFunds = otcCashoutFormToSettle.filter(item => item.hasOwnProperty("account") && item.account !== "All").map((otc) => ({
          accountId: companiesAccounts[savedData.company].find(item => item.label === otc.account).value,
          availableBalance: otc.availableBalance,
          requestedBalance: otc.requestedBalance,
          incomingFundsAmount: otc.incomingFunds,
          fxRate: otc.fxRate,
        }));
        const data = {
          notes: otcCashoutFormState.notes,
          status: otcCashoutFormState.status,
          confirmationLink: otcCashoutFormState.confirmationLink,
          totalBalance: otcCashoutFormToSettle[otcCashoutFormToSettle.length - 2].availableBalance,
          currency: otcCashoutFormToSettle[otcCashoutFormToSettle.length - 2].currency,
          date: savedData.date,
          companyId: savedData.company,
          pspId: savedData.psp,
          fxPair: otcCashoutFormToSettle[otcCashoutFormToSettle.length - 2].fxPair,
          incomingFunds: incomingFunds
        };

        crudActions
          .post(
            `v1/incoming/funds`,
            data,
          )
          .then(() => {
            this.setState({
              savedData: {},
              showAlert: true,
              alertType: "success",
              alertMessage: "Operation successfull.",
              lockButton: false,
              loadingDataInput: false
            });
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
              });
            }

            this.setState({
              lockButton: false,
              loadingDataInput: false
            });
          });
      }
    } else if (savedData.dataType === this.otcCashoutUpdate) {
      const isOtcCashoutUpdateFormValid = otcCashoutUpdateForm.every((form) => form.isFormValid());
      this.setState({
        saveClicked: true,
        debitCreditForm,
        lockButton: isOtcCashoutUpdateFormValid,
        loadingDataInput: isOtcCashoutUpdateFormValid
      });

      if (isOtcCashoutUpdateFormValid) {
        const data = savedData.invoice
          .map(invoice => ({
            companyId: savedData.company,
            id: invoice,
            newStatus: otcCashoutUpdateForm.find(row => row.invoice === invoice).status
          }));
        crudActions
          .put(
            `v1/incoming/funds`,
            data,
          )
          .then(() => {
            this.setState({
              savedData: {},
              showAlert: true,
              alertType: "success",
              alertMessage: "Operation successfull.",
              chooseOtcInvoice: false,
              lockButton: false,
              loadingDataInput: false
            });
          })
          .catch((err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
              });
            }

            this.setState({
              lockButton: false,
              loadingDataInput: false
            });
          });
      }
    }
  };

  isDateValid = (current) => {
    const { dateNow, lastSettlementDate, controls } = this.state;

    if ( lastSettlementDate && controls.dataType === "SETTLEMENT_BY_DATE") {
      return current.isBetween(lastSettlementDate, dateNow, undefined, '[]');
    }

    return current.isBefore(dateNow);
  };

  getTXValue = (midId) => {
    const { controls, mids } = this.state;
    const mid = mids.find(elem => elem.value === midId);
    const client = mid && mid.clients ? mid.clients.find(item => item.clientId === parseInt(controls.merchant)) : undefined;
    return client ? client.tx : '';
  };

  getValue = (savedValue, formValue) => {
    const isEmpty = (value) => value == null && value !== 0;

    if (isEmpty(savedValue) && isEmpty(formValue)) {
      return "";
    }

    return isEmpty(formValue) ? savedValue : formValue;
  };

  filterFields = (rowArray, type, element) => {
    const state = this.state;

    let finishedArray = [];

    if (type === "UPDATE_SETTLEMENT") {
      finishedArray = rowArray.map((elem) => {
        if (elem.display && !element[elem.name]) {
          return {};
        }

        if (element["toDate"] && element["fromDate"] && elem.name === "date") {
          return {};
        }

        return elem;
      });
    } else {
      finishedArray = rowArray.map((elem) => {
        if (elem.display && !state[elem.display]) {
          return {};
        }

        return elem;
      });
    }

    finishedArray.reverse();

    return finishedArray.reduce((accum, currVal) => {
      if (!currVal.fieldType) {
        accum.push(currVal);
      } else {
        accum.unshift(currVal);
      }

      return accum;
    }, []);
  };

  getUniqueData = () => {
    const { settlementDates } = this.state;

    if (!settlementDates) {
      return null;
    }

    const uniqueObjects = Array.from(new Set(settlementDates.map(obj => obj.value)))
      .map(value => {
        return settlementDates.find(obj => obj.value === value);
      });
    return uniqueObjects;
  };

  getMidsOptions = () => {
    const { controls } = this.state;
    if (controls.dataType === "SETTLEMENT_BY_REQUEST" ||
        controls.dataType === "UPDATE_SETTLEMENT") {
      return this.filterMidsByCurrency();
    } else if (
      controls.dataType === "SETTLEMENT_BY_AMOUNT" ||
      controls.dataType === "SETTLEMENT_BY_DATE"
    ) {
      return this.filterMidsByPSPAndCurrency();
    } else {
      return this.filterMids();
    }
  };

  getFilteredStatuses = (items, statusId) => {
    // id 18 - paid
    if (statusId === "18") {
      return items.filter(item => item.label === "Declined");
    }
    return items;
  };

  filterMidsByPSPAndCurrency = () => {
    const { controls, mids } = this.state;
    if (!controls.psp || !controls.merchant) {
      return [];
    }

    return mids.filter(
      (mid) =>
        mid.clients.some(
          (client) => client.clientId === parseInt(controls.merchant, 10)
        ) && controls["psp"].includes(mid.pspId.toString())
    ).filter(
      (mid) =>
        mid.clients.some(
          (client) => client.clientId === parseInt(controls.merchant, 10)
        ) && mid.currency === controls.currency
    );
  };

  filterDataTypes = () => {
    const { companiesClients, controls, dataTypes, pageType, otcActionType} = this.state;
    if (controls.dataType === 'SETTLE_AGENT') {
      return dataTypes;
    }

    if (pageType === "incomingFunds") {
      return otcActionType;
    }
    const selectedClient = companiesClients[controls.company].find(item => item.value === controls.merchant);
    if (selectedClient) {
      return dataTypes.filter(type => type.supportedBy.includes(selectedClient.applicationType));
    }
    return [];
  };

  onDeleteItem = () => {
    const { debitCreditForm } = this.state;
    const dynamicKey = `midsForCredit${debitCreditForm.length}`;
    this.fieldsMap.DEBIT_CREDIT = this.fieldsMap.DEBIT_CREDIT.slice(0, -1);
    this.setState((prevState) => ({
      debitCreditForm: prevState.debitCreditForm.slice(0, -1),
      [dynamicKey]: []
    }));
  };

  onAddDebitCreditItem = (debitMidId, debitBalance) => {
    const length = this.fieldsMap.DEBIT_CREDIT.length;
    this.fieldsMap["DEBIT_CREDIT"].push([
      {
        name: "debitMidId",
        label: "Debit Mid",
        fieldType: "select",
        flexGrow: "10",
        items: "midsForDebit",
      },
      {
        name: "debitBalance",
        label: "Debit Balance",
        fieldType: "input",
        flexGrow: "10",
        disabled: true,
        inputType: "number"
      },
      {
        name: "creditPsp",
        label: "Provider",
        fieldType: "select",
        flexGrow: "10",
        items: "creditPsps"
      },
      {
        name: "creditMidId",
        label: "Credit Mid",
        fieldType: "select",
        flexGrow: "10",
        inputType: "number",
        items: "midsForCredit" + (length + 1)
      },
      {
        name: "creditBalance",
        label: "Credit Balance",
        fieldType: "input",
        disabled: true,
        flexGrow: "10",
        inputType: "number"
      },
      {
        name: "creditAmount",
        label: "Credit Amount",
        fieldType: "input",
        flexGrow: "10",
      },
    ]);
    this.setState((prevState) => ({
      debitCreditForm: [...prevState.debitCreditForm, new Form(
        {
          debitMidId: debitMidId ? debitMidId : "",
          debitBalance: debitBalance ? debitBalance : "",
          creditMidId: "",
          creditBalance: "",
          creditAmount: "",
        },
        [
          {
            name: "debitMidId",
            type: "isNumber",
            rules: {
              required: true,
              min: 0
            },
          },
          {
            name: "debitBalance",
            type: "isNumber",
            rules: {
              required: true,
            },
          },
          {
            name: "creditMidId",
            type: "isNumber",
            rules: {
              required: true,
            },
          },
          {
            name: "creditBalance",
            type: "isNumber",
            rules: {
              required: true,
            },
          },
          {
            name: "creditAmount",
            type: "isNumber",
            rules: {
              required: true,
            },
          }
        ]
      )],
    }));
  };

  onAutoCredit = (index) => {
    const { debitCreditForm } = this.state;
    const debitCreditFormSingle = debitCreditForm[index];
    if (!debitCreditFormSingle.creditAmount || debitCreditFormSingle.creditAmount !== debitCreditFormSingle.debitBalance) {
      debitCreditFormSingle.creditAmount = debitCreditFormSingle.debitBalance > 0 && this.isDebitAutoAvailable(debitCreditFormSingle.debitMidId, index) ? debitCreditFormSingle.debitBalance : debitCreditFormSingle.creditAmount;
    } else {
      debitCreditFormSingle.creditAmount = "";
    }
    this.setState({
      debitCreditForm: debitCreditForm,
    });
  };

  onAutoCashout = (index) => {
    const { otcCashoutForm } = this.state;
    const otcCashoutFormSingle = otcCashoutForm[index];
    if (!otcCashoutFormSingle.requestedBalance || otcCashoutFormSingle.availableBalance !== otcCashoutFormSingle.requestedBalance) {
      otcCashoutFormSingle.requestedBalance = otcCashoutFormSingle.availableBalance;
    } else {
      otcCashoutFormSingle.requestedBalance = "";
    }
    this.reCalculateIncomingFunds(otcCashoutForm);
    this.reCalculateAllRequestedBalance(otcCashoutForm);
    this.setState({
      otcCashoutForm: otcCashoutForm,
    });
  };

  isDebitAutoAvailable = (midId, indx) => {
    const { debitCreditForm } = this.state;
    return debitCreditForm.filter((form, idx)  => idx !== indx && form.debitMidId === midId && form.creditAmount).length === 0;
  };

  isCreditDebit = () => {
    const { savedData } = this.state;
    return savedData.dataType === "DEBIT_CREDIT";
  };

  filterAccountsAndSelect = (accounts, fxPair, psp) => {
    return accounts.filter(account => (account.fxPair === fxPair || (account.currency === fxPair.split("/")[0] && account.currency === fxPair.split("/")[1]))
      && account.pspId === parseInt(psp));
  };

  filterAndMapFxPairs = (fxPairs, psp, accounts) => {
    const accountsFxPairs = accounts.filter(account => account.pspId === parseInt(psp)).map(account => account.fxPair);
    return fxPairs.filter(elem => accountsFxPairs.includes(elem) || elem.split("/")[0] === elem.split("/")[1]).map((pair) => {
      return { value: pair, label: this.getFxPairValue(pair) };
    });
  };

  multiSelectInvoices = () => {
    const { otcCashoutInvoiceFullInfo, controls } = this.state;
    return <Multiselect
      id="invoice"
      name="invoice"
      isError={ controls.errors.has("invoice") }
      selectedItems={ controls.date && controls.currency && otcCashoutInvoiceFullInfo[controls.date] ? this.mapSelectedItems(
        "invoice",
        controls.date,
        "otcCashoutInvoiceFullInfo"
      ) : [] }
      className={
        controls.errors.has("invoice") ? "error-field" : ""
      }
      required={ true }
      clearable={ false }
      disabled={ !controls.company || !controls.currency }
      onChange={ (value) =>
        this.onValueChange(value, "invoice")
      }
      items={
        controls.date && controls.currency && otcCashoutInvoiceFullInfo[controls.date]
          ? otcCashoutInvoiceFullInfo[controls.date]
          : []
      }
    />;
  };

  getLineSeparator = () => {
    return <Row  wrap={ true } horizontal="start" vertical="end" style={ { width: "100%" } }>
      <Column flexGrow={ 1 } vertical="start" alignSelf="start" className="input-column">
        <hr className="line-separator" />
      </Column>
    </Row>;
  };

  render() {
    const {
      alertMessage,
      alertType,
      companies,
      companiesBaseCurrencies,
      companiesAccounts,
      companiesClients,
      companiesPsps,
      controls,
      date,
      filteredFxPairs,
      loadingDataInput,
      loadingLookups,
      lockButton,
      notes,
      otcCashoutStatuses,
      otcCashoutInvoiceDates,
      chooseOtcInvoice,
      pageType,
      runningBalanceTypes,
      runningBalanceValues,
      savedData,
      savedDataList,
      settlementMids,
      settlementStatuses,
      showAlert,
      showSecondPanel,
      withheldFunds
    } = this.state;

    let selectedForm = this.state[this.formsMap[savedData.dataType]];

    const { dataType } = controls;

    const isIncomingFunds = pageType === "incomingFunds";

    const displayMerchantSelect =  dataType !== 'SETTLE_AGENT' && pageType !== "incomingFunds";

    const displayCurrencySelect = (dataType !== "OTC_CASHOUT" && dataType !== "" && dataType !== "RUNNING_BALANCE" && dataType !== "TOP_UP_PAYOUT_WALLET" && dataType !== "WITHHELD_FUNDS" && dataType !== "SETTLE_AGENT" && dataType !== "DEBIT_CREDIT");

    const displayPSPMultiselect = dataType === "SETTLEMENT_BY_AMOUNT" || dataType === "SETTLEMENT_BY_DATE";

    const displayPSPSelect = pageType === "settlements" && (dataType === "" || dataType === "RUNNING_BALANCE" || dataType === "TOP_UP_PAYOUT_WALLET" || dataType === "WITHHELD_FUNDS" || dataType === "DEBIT_CREDIT");

    return (
      <Row flexGrow={ 1 } className="module apidata settlements" vertical="start">
        <Column flexGrow={ 1 }>
          <PageHeader
            title={ isIncomingFunds ? "Inbound Settlement" : "Data Input" }
            lastUpdate={ this.state.lastUpdate }
            img={ ManualInputIcon }
          />
          <Row flexGrow={ 1 } horizontal="start" wrap={ true } vertical="start">
            <Column
              flexGrow={ 1 }
              vertical="start"
              style={ {
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 15,
                width: "100%",
              } }
            >
              <Panel>
                <Panel.Heading>
                  <Panel.Title>SELECT</Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  {loadingLookups ? (
                    <div
                      style={ {
                        width: "100%",
                        height: "400px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      } }
                    >
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div
                      className="panel-content"
                      style={ { overflow: "unset" } }
                    >
                      <Row
                        flexGrow={ 1 }
                        wrap={ true }
                        horizontal="start"
                        vertical="end"
                        style={ { width: "100%" } }
                      >
                        {/* WHITE LABEL */}

                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> White Label </label>
                          <Select
                            id="company"
                            name="company"
                            value={ controls.company || "" }
                            className={
                              controls.errors.has("company")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) =>
                              this.onValueChange(value, "company")
                            }
                            options={ companies }
                          />
                        </Column>

                        {/* MERCHANT */ }

                        {displayMerchantSelect && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Merchant </label>
                            <Select
                              id="merchant"
                              name="merchant"
                              value={ controls.merchant || "" }
                              className={
                                controls.errors.has("merchant")
                                  ? "error-field"
                                  : ""
                              }
                              required={ true }
                              clearable={ false }
                              disabled={ !controls.company }
                              onChange={ (value) =>
                                this.onValueChange(value, "merchant")
                              }
                              options={
                                controls.company
                                  ? companiesClients[controls.company]
                                  : []
                              }
                            />
                          </Column>
                        ) }

                        {/* DATA TYPE */}

                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> { !isIncomingFunds ? "Data Type" : "Action Type" } </label>
                          <Select
                            id="dataType"
                            name="dataType"
                            value={ controls.dataType || "" }
                            className={
                              controls.errors.has("dataType")
                                ? "error-field"
                                : ""
                            }
                            required={ true }
                            clearable={ false }
                            disabled={ !controls.company || (!controls.merchant && (dataType !== 'SETTLE_AGENT' && !isIncomingFunds)) }
                            onChange={ (value) =>
                              this.onValueChange(value, "dataType")
                            }
                            options={ (controls.merchant || dataType === 'SETTLE_AGENT' || isIncomingFunds) ? this.filterDataTypes() : [] }
                          />
                        </Column>

                        {/* OTC Cashout Provider */}
                        {isIncomingFunds && (
                          <>
                            { dataType === "OTC_CASHOUT" && (
                              <Column
                                flexGrow={ 1 }
                                vertical="start"
                                alignSelf="start"
                                className="input-column"
                              >
                                <label> Provider </label>
                                <Select
                                  id="psp"
                                  name="psp"
                                  value={ controls.psp || "" }
                                  className={
                                    controls.errors.has("psp")
                                      ? "error-field"
                                      : ""
                                  }
                                  required={ true }
                                  clearable={ false }
                                  disabled={ !controls.company }
                                  onChange={ (value) =>
                                    this.onValueChange(value, "psp")
                                  }
                                  options={
                                    controls.company
                                      ? companiesPsps[controls.company]
                                      : []
                                  }
                                />
                              </Column>
                            )}
                          </>
                        )}

                        {/* AGENTS */ }

                        {dataType === 'SETTLE_AGENT' && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Agents </label>
                            <Select
                              id="agentId"
                              name="agentId"
                              value={ controls.agent || "" }
                              className={
                                controls.errors.has("agent")
                                  ? "error-field"
                                  : ""
                              }
                              required={ true }
                              clearable={ false }
                              onChange={ (value) =>
                                this.onValueChange(value, "agent")
                              }
                              options={ this.state.agents }
                            />
                          </Column>
                        )}

                        {/* DATE or STATUS */ }

                        {dataType === "SETTLEMENT_BY_DATE" || dataType === "SETTLE_AGENT" ? (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Date </label>
                            <Datetime
                              type="text"
                              timeFormat={ dataType === 'SETTLE_AGENT' && "HH:mm" }
                              inputProps={ {
                                onKeyDown: (e) => {
                                  e.preventDefault();
                                },
                              } }
                              dateFormat="DD.MM.YYYY"
                              value={ controls.date || "" }
                              isValidDate={ (event) => this.isDateValid(event) }
                              onChange={ (event) => this.onValueChange({ value: Datetime.moment(event) }, "date") }
                              required={ true }
                            />
                          </Column>
                        ) : dataType === "UPDATE_SETTLEMENT" ? (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Status </label>
                            <Select
                              id="settlementStatus"
                              name="settlementStatus"
                              value={ controls.settlementStatus || "" }
                              className={
                                controls.errors.has("settlementStatus")
                                  ? "error-field"
                                  : ""
                              }
                              required={ true }
                              clearable={ false }
                              onChange={ (value) =>
                                this.onValueChange(value, "settlementStatus")
                              }
                              options={ settlementStatuses.filter(
                                (elem) =>
                                  elem.label === "Requested" ||
                                  elem.label === "Paid" ||
                                  elem.label === "In Progress"
                              ) }
                            />
                          </Column>
                        ) : dataType === this.otcCashoutUpdate ? (
                          <>
                            <Column
                              flexGrow={ 1 }
                              vertical="start"
                              alignSelf="start"
                              className="input-column"
                            >
                              <label> Status </label>
                              <Select
                                id="otcCashoutStatus"
                                name="otcCashoutStatus"
                                value={ controls.otcCashoutStatus || "" }
                                className={
                                  controls.errors.has("otcCashoutStatus")
                                    ? "error-field"
                                    : ""
                                }
                                required={ true }
                                clearable={ false }
                                onChange={ (value) =>
                                  this.onValueChange(value, "otcCashoutStatus")
                                }
                                options={ otcCashoutStatuses }
                              />
                            </Column>
                            <Column
                              flexGrow={ 1 }
                              vertical="start"
                              alignSelf="start"
                              className="input-column"
                            >
                              <span />
                            </Column>
                          </>
                        ) : (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <span />
                          </Column>
                        )}
                      </Row>
                      <Row
                        flexGrow={ 1 }
                        wrap={ true }
                        horizontal="start"
                        vertical="end"
                        style={ { width: "100%" } }
                      >
                        {/* CURRENCY (optional) */}

                        {displayCurrencySelect && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Currency </label>
                            <Select
                              id="currency"
                              name="currency"
                              value={ controls.currency || "" }
                              className={
                                controls.errors.has("currency")
                                  ? "error-field"
                                  : ""
                              }
                              required={ true }
                              clearable={ false }
                              onChange={ (value) =>
                                this.onValueChange(value, "currency")
                              }
                              options={
                                companiesBaseCurrencies[controls.company]
                              }
                            />
                          </Column>
                        )}

                        {/* OTC Cashout Accounts && Fx Pair */}
                        {isIncomingFunds && dataType === "OTC_CASHOUT" && (
                          <>
                            <Column
                              flexGrow={ 1 }
                              vertical="start"
                              alignSelf="start"
                              className="input-column"
                            >
                              <label> FX Pair </label>
                              <Select
                                id="currency"
                                name="currency"
                                value={ controls.fxPair || "" }
                                className={
                                  controls.errors.has("fxPair")
                                    ? "error-field"
                                    : ""
                                }
                                disabled={ !controls.company || !controls.psp }
                                required={ true }
                                clearable={ false }
                                onChange={ (value) =>
                                  this.onValueChange(value, "fxPair")
                                }
                                options={ filteredFxPairs }
                              />
                            </Column>
                            <Column
                              flexGrow={ 1 }
                              vertical="start"
                              alignSelf="start"
                              className="input-column"
                            >
                              <label> Accounts </label>
                              <Multiselect
                                id="accountIds"
                                name="accountIds"
                                isError={ controls.errors.has("accountIds") }
                                selectedItems={ this.mapSelectedItems(
                                  "accountIds",
                                  controls.company,
                                  "companiesAccounts"
                                ) }
                                className={
                                  controls.errors.has("accountIds") ? "error-field" : ""
                                }
                                required={ true }
                                clearable={ false }
                                disabled={ !controls.company || !controls.psp || !controls.fxPair }
                                onChange={ (value) =>
                                  this.onValueChange(value, "accountIds")
                                }
                                items={ controls.company && controls.fxPair && controls.psp
                                  ? this.filterAccountsAndSelect(companiesAccounts[controls.company], controls.fxPair, controls.psp)
                                  : [] }
                              />
                            </Column>
                          </>
                        )}

                        {/* PSP */}

                        {displayPSPMultiselect && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Provider </label>
                            <Multiselect
                              id="psp"
                              name="psp"
                              isError={ controls.errors.has("psp") }
                              selectedItems={ this.mapSelectedItems(
                                "psp",
                                controls.company,
                                "companiesPsps"
                              ) }
                              className={
                                controls.errors.has("psp") ? "error-field" : ""
                              }
                              required={ true }
                              clearable={ false }
                              disabled={ !controls.company }
                              onChange={ (value) =>
                                this.onValueChange(value, "psp")
                              }
                              items={
                                controls.company
                                  ? companiesPsps[controls.company]
                                  : []
                              }
                            />
                          </Column>
                        )}

                        {displayPSPSelect && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Provider </label>
                            <Select
                              id="psp"
                              name="psp"
                              value={ controls.psp || "" }
                              className={
                                controls.errors.has("psp")
                                  ? "error-field"
                                  : ""
                              }
                              required={ true }
                              clearable={ false }
                              disabled={ !controls.company }
                              onChange={ (value) =>
                                this.onValueChange(value, "psp")
                              }
                              options={
                                controls.company
                                  ? companiesPsps[controls.company]
                                  : []
                              }
                            />
                          </Column>
                        )}

                        {/* MID */}

                        {!isIncomingFunds && dataType !== "SETTLE_AGENT" && dataType !== "DEBIT_CREDIT" && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> MID </label>
                            {(dataType !== "" &&
                              dataType !== "RUNNING_BALANCE" &&
                              dataType !== "TOP_UP_PAYOUT_WALLET" &&
                              dataType !== "WITHHELD_FUNDS") ? (
                                <Multiselect
                                  isError={ controls.errors.has("midIds") }
                                  selectedItems={ this.mapSelectedMids("midIds") }
                                  className={
                                    controls.errors.has("midIds")
                                      ? "error-field"
                                      : ""
                                  }
                                  disabled={
                                    (dataType !== "" &&
                                      dataType !== "RUNNING_BALANCE" &&
                                      dataType !== "TOP_UP_PAYOUT_WALLET")
                                      ? !controls.company ||
                                      !controls.merchant ||
                                      !controls.currency
                                      : !controls.company ||
                                      !controls.merchant ||
                                      !controls.psp
                                  }
                                  items={ this.getMidsOptions() }
                                  onChange={ (value) =>
                                    this.onValueChange(value, "midIds")
                                  }
                                />
                              ) : (
                                <Select
                                  id="midIds"
                                  name="midIds"
                                  value={ controls.midIds || "" }
                                  className={
                                    controls.errors.has("midIds") ? "error-field" : ""
                                  }
                                  required={ true }
                                  clearable={ false }
                                  disabled={
                                    !controls.company ||
                                  !controls.merchant ||
                                  !controls.psp
                                  }
                                  onChange={ (value) =>
                                    this.onValueChange(value, "midIds")
                                  }
                                  options={ this.filterMids() }
                                />
                              )}
                          </Column>
                        )}

                        {/* DATE (optional) */}

                        { dataType !== "SETTLEMENT_BY_AMOUNT" &&
                          dataType !== "SETTLEMENT_BY_DATE" &&
                          dataType !== "SETTLE_AGENT" && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Date </label>
                            {dataType === "UPDATE_SETTLEMENT" || dataType === "SETTLEMENT_BY_REQUEST" ? (
                              <Select
                                id="date"
                                name="date"
                                value={ controls.updateSettlementDate || "" }
                                className={
                                  controls.errors.has("updateSettlementDate")
                                    ? "error-field"
                                    : ""
                                }
                                required={ true }
                                clearable={ false }
                                disabled={
                                  dataType === "SETTLEMENT_BY_REQUEST"
                                    ? (!controls.company ||
                                    !controls.merchant ||
                                    !controls.currency ||
                                    !controls.midIds.length)
                                    : (!controls.company ||
                                    !controls.merchant ||
                                    !controls.settlementStatus ||
                                    !controls.currency ||
                                    !controls.midIds.length)
                                }
                                onChange={ (value) =>
                                  this.onValueChange(
                                    value,
                                    "updateSettlementDate"
                                  )
                                }
                                options={ this.getUniqueData() }
                              />
                            ) : (dataType === this.otcCashoutUpdate) ? (
                              <Select
                                id="date"
                                name="date"
                                value={ controls.date || "" }
                                className={
                                  controls.errors.has("date")
                                    ? "error-field"
                                    : ""
                                }
                                disabled={ !controls.currency }
                                required={ true }
                                clearable={ false }
                                onChange={ (value) =>
                                  this.onValueChange(value, "date")
                                }
                                options={ otcCashoutInvoiceDates }
                              />
                            ) : (
                              <Datetime
                                type="text"
                                timeFormat={ false }
                                inputProps={ {
                                  onKeyDown: (e) => {
                                    e.preventDefault();
                                  },
                                  disabled: dataType === "TOP_UP_PAYOUT_WALLET" || dataType === "DEBIT_CREDIT" || dataType === "OTC_CASHOUT"
                                } }
                                dateFormat="DD.MM.YYYY"
                                value={ date || "" }
                                isValidDate={ (event) => this.isDateValid(event) }
                                onChange={ (event) =>
                                  this.handleDateChange(event)
                                }
                                required={ true }
                              />
                            )}
                          </Column>
                        ) }

                        {(dataType === "DEBIT_CREDIT" || dataType === this.otcCashoutUpdate) && (
                          <Column
                            flexGrow={ 1 }
                            vertical="start"
                            alignSelf="start"
                            className="input-column"
                          >
                            <label> Invoices </label>
                            { this.multiSelectInvoices() }
                          </Column>
                        )}
                        { dataType === "SETTLE_AGENT" && (
                          <>
                            {[0, 1, 2].map(column => (
                              <Column
                                key={ column }
                                flexGrow={ 1 }
                                className="input-column empty-column"
                              >
                                <span />
                              </Column>
                            ))}
                          </>
                        )}

                        <Column
                          flexGrow={ 1 }
                          className="input-column fullwidth-button"
                        >
                          <CustomButton
                            title="Filter"
                            type="button"
                            disabled={ lockButton }
                            onClick={ (e) => this.onChangeFilter() }
                          />
                        </Column>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
              {loadingDataInput && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>{ chooseOtcInvoice ? "SELECT INVOICES" : "DATA ENTRIES" }</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div
                      style={ {
                        width: "100%",
                        height: "400px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      } }
                    >
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              )}
              {chooseOtcInvoice && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>SELECT INVOICES</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div
                      className={ `panel-content` }
                      style={ { overflow: "unset" } }
                    >
                      <Row
                        flexGrow={ 1 }
                        wrap={ true }
                        horizontal="start"
                        vertical="end"
                        style={ { width: "100%" } }
                      >
                        <Column
                          flexGrow={ 1 }
                          vertical="start"
                          alignSelf="start"
                          className="input-column"
                        >
                          <label> Invoices </label>
                          { this.multiSelectInvoices() }
                        </Column>
                        <Column
                          flexGrow={ 1 }
                          className="input-column empty-column"
                        >
                          <span />
                        </Column>
                        <Column
                          flexGrow={ 1 }
                          className="input-column empty-column"
                        >
                          <span />
                        </Column>
                        <Column
                          flexGrow={ 1 }
                          className="input-column fullwidth-button"
                        >
                          <CustomButton
                            title="Select"
                            type="button"
                            disabled={ lockButton }
                            onClick={ (e) => this.onSelectInvoice() }
                          />
                        </Column>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              ) }
              { (showSecondPanel && !loadingDataInput && savedData.dataType === "RUNNING_BALANCE") && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>DATA ENTRIES</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div
                      className="panel-content rb-form"
                      style={ { overflow: "unset" } }
                    >
                      <Row
                        wrap={ true }
                        horizontal="start"
                        vertical="end"
                        className="running-balance-form"
                      >
                        <RunningBalanceComponent
                          items={ runningBalanceTypes }
                          editable={ true }
                          selectedItems={ runningBalanceValues }
                          onTemporaryChange={ this.onTypesTemporaryChange }
                          onChange={ this.onTypesChange }
                        />
                          
                      </Row>
                      {this.checkPageAccess("DATA_INPUT_WRITE") && (
                        <Row
                          flexGrow={ 1 }
                          wrap={ true }
                          horizontal="end"
                          vertical="end"
                          style={ { width: "100%" } }
                        >
                          <Column className="input-column fullwidth-button">
                            <CustomButton
                              title="Save"
                              type="button"
                              onClick={ (e) => this.onSaveDataEntries() }
                            />
                          </Column>
                        </Row>
                      )}
                    </div>
                  </Panel.Body>
                </Panel>
              )}

              {showSecondPanel && !loadingDataInput && savedData.dataType !== "RUNNING_BALANCE" &&
                savedData.dataType !== "UPDATE_SETTLEMENT" && savedData.dataType !== "WITHHELD_FUNDS" && (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> { !this.isCreditDebit() ? 'DATA ENTRIES' : 'DEBIT MID' }</Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div
                      className={ `panel-content amount-form` }
                      style={ { overflow: "unset" } }
                    >
                      {(savedData.dataType === "SETTLEMENT_BY_AMOUNT" ||
                        savedData.dataType === "SETTLEMENT_BY_DATE" ||
                        savedData.dataType === "SETTLEMENT_BY_REQUEST") && (
                        <>
                          {settlementMids.map((mid, index) => (
                            <Row
                              key={ index }
                              wrap={ true }
                              horizontal="start"
                              vertical="center"
                              style={ { width: "100%" } }
                              className="mid-row"
                            >
                              <Column flexGrow="0.5" className="input-column">
                                <label>MID</label>
                                <input
                                  id={ `${mid.id}-${mid.name}` }
                                  name="name"
                                  type="text"
                                  value={ mid.name }
                                  className="form-control"
                                  disabled={ true }
                                  onChange={ (value) =>
                                    this.onMidRowChange(value, "name")
                                  }
                                />
                              </Column>

                              <Column flexGrow="0.25" className="input-column">
                                <label>Provider</label>
                                <input
                                  id={ `${mid.id}-${mid.name}` }
                                  name="providerName"
                                  type="text"
                                  value={ mid.providerName }
                                  className="form-control"
                                  disabled={ true }
                                  onChange={ (value) =>
                                    this.onMidRowChange(value, "providerName")
                                  }
                                />
                              </Column>

                              {savedData.dataType === "SETTLEMENT_BY_DATE" && (
                                <>
                                  <Column flexGrow={ 0 } className="input-column">
                                    <label>From Date</label>
                                    <input
                                      id={ `${mid.id}-${mid.name}` }
                                      name="fromDate"
                                      type="text"
                                      value={ mid.fromDate }
                                      className="form-control"
                                      disabled={ true }
                                      onChange={ (value) =>
                                        this.onSettlementChange(value, "fromDate")
                                      }
                                    />
                                  </Column>

                                  <Column flexGrow="0.5" className="input-column">
                                    <label>
                                      To Date{' '}<span className="txValue">(T+{this.getTXValue(mid.id)})</span>
                                    </label>
                                    <input
                                      id={ `${mid.id}-${mid.name}` }
                                      name="toDate"
                                      type="text"
                                      value={ mid.toDate }
                                      className="form-control"
                                      disabled={ true }
                                      onChange={ (value) =>
                                        this.onSettlementChange(value, "toDate")
                                      }
                                    />
                                    <span className="txValue-inputMask">(T+{this.getTXValue(mid.id)})</span>
                                  </Column>
                                </>
                              )}

                              {savedData.dataType === "SETTLEMENT_BY_AMOUNT" && (
                                <Column flexGrow="0.5" className="input-column">
                                  <label>
                                    Date{' '}<span className="txValue">(T+{this.getTXValue(mid.id)})</span>
                                  </label>
                                  <input
                                    id={ `${mid.id}-${mid.name}` }
                                    name="date"
                                    type="text"
                                    value={ mid.date }
                                    className="form-control"
                                    disabled={ true }
                                    onChange={ (value) =>
                                      this.onSettlementChange(value, "date")
                                    }
                                  />
                                  <span className="txValue-inputMask">(T+{this.getTXValue(mid.id)})</span>
                                </Column>
                              )}

                              {savedData.dataType === "SETTLEMENT_BY_AMOUNT" && (
                                <Column flexGrow="0.25" className="input-column">
                                  <label>
                                    Withheld Funds
                                  </label>
                                  <input
                                    id={ `${mid.id}-${mid.name}` }
                                    name="withheldFunds"
                                    type="text"
                                    value={ mid.withheldFunds }
                                    className="form-control"
                                    disabled={ true }
                                    onChange={ (value) =>
                                      this.onSettlementChange(value, "withheldFunds")
                                    }
                                  />
                                </Column>
                              )}
                              
                              {savedData.dataType !== "SETTLEMENT_BY_DATE" && (
                                <Column
                                  flexGrow="0.25"
                                  className="input-column"
                                >
                                  <label>Payout Wallet</label>
                                  <input
                                    id={ `${mid.id}-${mid.name}` }
                                    name="payoutWalletBalance"
                                    type="text"
                                    value={ mid.payoutWalletBalance }
                                    className="form-control"
                                    disabled={ true }
                                    onChange={ (value) =>
                                      this.onSettlementChange(
                                        value,
                                        "payoutWalletBalance"
                                      )
                                    }
                                  />
                                  {mid.payoutWalletType !== "" && (
                                    <span className={ `txValue-inputMask ${mid.payoutWalletType === "N/A" ? 'full-width' : ''}` }>
                                      { `(${mid.payoutWalletType})` }
                                    </span>
                                  )}
                                </Column>
                              )}

                              <Column flexGrow="0.25" className="input-column">
                                <label>Pending Payouts</label>
                                <input
                                  id={ `${mid.id}-${mid.name}` }
                                  name="pendingPayouts"
                                  type="text"
                                  value={ mid.pendingPayouts }
                                  className="form-control"
                                  disabled={ true }
                                  onChange={ (value) =>
                                    this.onMidRowChange(
                                      value,
                                      "pendingPayouts"
                                    )
                                  }
                                />
                              </Column>

                              <Column flexGrow="0.25" className="input-column">
                                <label>Available Balance</label>
                                <input
                                  id={ `${mid.id}-${mid.name}` }
                                  name="availableBalance"
                                  type="text"
                                  value={ mid.availableBalance }
                                  className="form-control"
                                  disabled={ true }
                                  onChange={ (value) =>
                                    this.onMidRowChange(
                                      value,
                                      "availableBalance"
                                    )
                                  }
                                />
                              </Column>

                              <Column flexGrow="0.25" className="input-column">
                                <label>Balance to Settle</label>
                                <input
                                  id={ `${mid.id}-${mid.name}` }
                                  name="balanceToSettle"
                                  type="text"
                                  value={ mid.balanceToSettle }
                                  className="form-control"
                                  disabled={ savedData.dataType !== "SETTLEMENT_BY_AMOUNT" }
                                  onChange={ (value) =>
                                    this.onMidRowChange(
                                      value,
                                      "balanceToSettle",
                                      index
                                    )
                                  }
                                />
                              </Column>
                              
                              <Column
                                flexGrow={ 0 }
                                className="input-column checkbox-column"
                              >
                                <label
                                  className="container"
                                  style={ { width: "20px", marginTop: "10px" } }
                                >
                                  <input
                                    type="checkbox"
                                    disabled={ mid.disabled }
                                    checked={ mid.isChecked }
                                    onChange={ () => this.onMidSelect(index, savedData.dataType) }
                                  />
                                  <span className="checkmark" disabled={ mid.disabled }></span>
                                </label>
                              </Column>
                            </Row>
                          ))}
                          <hr />
                        </>
                      )}

                      { (savedData.dataType === "SETTLEMENT_BY_AMOUNT" ||
                            savedData.dataType === "SETTLEMENT_BY_DATE" ||
                            savedData.dataType === "SETTLEMENT_BY_REQUEST" ||
                            savedData.dataType === "UPDATE_SETTLEMENT" ||
                            savedData.dataType === "TOP_UP_PAYOUT_WALLET" ||
                            savedData.dataType === "SETTLE_AGENT" || this.isCreditDebit()
                          || savedData.dataType === "SETTLE_AGENT" || savedData.dataType === "OTC_CASHOUT" ||
                          savedData.dataType === this.otcCashoutUpdate) &&
                          this.fieldsMap[savedData.dataType].map(
                            (rowArray, index) =>  {
                              
                              if (this.isCreditDebit() || savedData.dataType === "OTC_CASHOUT" || savedData.dataType === this.otcCashoutUpdate) {
                                selectedForm = this.state[this.formsMap[savedData.dataType]][index];
                              }
                              return (
                                (rowArray.length === 0) ? this.getLineSeparator() :
                                  <Row
                                    key={ index }
                                    wrap={ true }
                                    horizontal="start"
                                    vertical="end"
                                    style={ { width: savedData.dataType === "TOP_UP_PAYOUT_WALLET" || savedData.dataType === "SETTLE_AGENT" ? "70%" : "100%" } }
                                  >
                                    {this.filterFields(rowArray).map(
                                      (fieldElem, elemIndex) => (
                                        <Column
                                          key={ `${fieldElem.name}-${elemIndex}` }
                                          flexGrow={
                                            fieldElem.flexGrow !== undefined
                                              ? parseInt(fieldElem.flexGrow)
                                              : 1
                                          }
                                          className={ fieldElem.fieldType !== 'checkBox' ? 'input-column' : 'input-column checkbox-column' }
                                        >
                                          <label> {fieldElem.label} </label>
                                          {fieldElem.fieldType === "date" && (
                                            <img
                                              src={ Calendar }
                                              className="calendar-svg"
                                              alt="calendar"
                                            />
                                          )}
                                          {fieldElem.fieldType === "select" ? (
                                            <Select
                                              id={ fieldElem.name }
                                              name={ fieldElem.name }
                                              value={
                                                savedData[fieldElem.name] ||
                                            selectedForm[fieldElem.name] ||
                                            ""
                                              }
                                              className={
                                                selectedForm.errors.has(
                                                  fieldElem.name
                                                )
                                                  ? "error-field"
                                                  : ""
                                              }
                                              required={ true }
                                              clearable={ false }
                                              disabled={ fieldElem.disabled }
                                              onChange={ (value) =>
                                                !this.isCreditDebit() ? this.onSettlementChange(
                                                  value,
                                                  fieldElem.name,
                                                  this.formsMap[savedData.dataType],
                                                  this.state[this.formsMap[savedData.dataType]], index
                                                ) : this.onCreditDebitChange(value,
                                                  fieldElem.name,
                                                  this.formsMap[savedData.dataType],
                                                  index)
                                              }
                                              options={
                                                savedData[fieldElem.name] ||
                                            this.state[fieldElem.items]
                                              }
                                            />
                                          ) : !fieldElem.fieldType ? (
                                            <span/>
                                          ) : fieldElem.fieldType === 'textarea' ? (
                                            <TextareaAutosize
                                              cacheMeasurements
                                              value={ this.getValue(
                                                savedData[fieldElem.name],
                                                selectedForm[fieldElem.name]
                                              ) }
                                              onChange={ (value) =>
                                                !this.isCreditDebit() ? this.onSettlementChange(
                                                  value,
                                                  fieldElem.name,
                                                  this.formsMap[savedData.dataType],
                                                  this.state[this.formsMap[savedData.dataType]], index
                                                ) : this.onCreditDebitChange(value,
                                                  fieldElem.name,
                                                  this.formsMap[savedData.dataType],
                                                  index) }
                                              className="form-control"
                                            />
                                          ) : fieldElem.fieldType === 'checkBox' ? (
                                            <label
                                              className="container"
                                              style={ { width: "20px", marginTop: "10px" } }
                                            >
                                              { fieldElem.empty ? (<span></span>) : (<>
                                                <input
                                                  type="checkbox"
                                                  disabled={ !selectedForm[fieldElem.disabledField] }
                                                  checked={ selectedForm[fieldElem.enteredField] && selectedForm[fieldElem.disabledField] === parseFloat(selectedForm[fieldElem.enteredField]) }
                                                  onChange={ () => fieldElem.autoMethod(index) }
                                                />
                                                <span className="checkmark"
                                                  disabled={ !selectedForm[fieldElem.disabledField] }></span>
                                              </>) }
                                            </label>
                                          ) : (
                                            <input
                                              id={ fieldElem.name }
                                              name={ fieldElem.name }
                                              type={ fieldElem.inputType || "text" }
                                              value={ this.getValue(
                                                savedData[fieldElem.name],
                                                selectedForm[fieldElem.name]
                                              ) }
                                              className={ `form-control ${
                                                selectedForm.errors.has(
                                                  fieldElem.name
                                                )
                                                  ? "error-field"
                                                  : ""
                                              }` }
                                              disabled={ fieldElem.disabled }
                                              style={ {
                                                paddingLeft:
                                              fieldElem.fieldType === "date"
                                                ? 35
                                                : 10,
                                              } }
                                              onChange={ (value) =>
                                                !this.isCreditDebit() ? this.onSettlementChange(
                                                  value,
                                                  fieldElem.name,
                                                  this.formsMap[savedData.dataType],
                                                  this.state[this.formsMap[savedData.dataType]], index) : this.onCreditDebitChange(value,
                                                  fieldElem.name,
                                                  this.formsMap[savedData.dataType],
                                                  index)
                                              }
                                            />
                                          )}
                                        </Column>
                                      )
                                    )}
                                    {this.isCreditDebit() && (
                                      <>
                                        <Column
                                          flexGrow={ 0 }
                                          className="input-column checkbox-column"
                                        >
                                          <label
                                            className="container"
                                            style={ { width: "20px", marginTop: "10px" } }
                                          >
                                            <input
                                              type="checkbox"
                                              disabled={ !selectedForm["debitBalance"] }
                                              checked={ selectedForm["debitBalance"] && selectedForm["debitBalance"] === parseFloat(selectedForm["creditAmount"]) }
                                              onChange={ () => this.onAutoCredit(index) }
                                            />
                                            <span className="checkmark" disabled={ !selectedForm["debitBalance"] }></span>
                                          </label>
                                        </Column>
                                        <Column flexGrow={ 1 } vertical='start' alignSelf='center' style={ {width: "30px"} }>
                                          {index === this.fieldsMap[savedData.dataType].length - 1 && this.fieldsMap[savedData.dataType].length !== 1 && (
                                            <button
                                              className="delete debit"
                                              onClick={ () => this.onDeleteItem(index) }
                                            >
                                        X
                                            </button>
                                          )}
                                        </Column>
                                        <Column flexGrow={ 1 } vertical='start' alignSelf='center' style={ {width: "30px"} }>
                                          {index === this.fieldsMap[savedData.dataType].length - 1 && (
                                            <button
                                              className="add debit"
                                              onClick={ () => this.onAddDebitCreditItem() }
                                            >
                                              <span className="plus"> + </span>
                                            </button>
                                          )}
                                        </Column>
                                      </>
                                    )}
                                  </Row>
                              );}
                            
                          )}
                      
                      {this.checkPageAccess("DATA_INPUT_WRITE") && (
                        <Row
                          wrap={ true }
                          horizontal="start"
                          vertical="end"
                          style={ { width: "100%" } }
                        >
                          {[0, 1, 2].map(column => (
                            <Column
                              key={ column }
                              flexGrow={ 1 }
                              className="input-column empty-column"
                            >
                              <span />
                            </Column>
                          ))}
                          <Column
                            flexGrow={ 1 }
                            className="input-column"
                          >
                            { savedData.dataType === "SETTLEMENT_BY_DATE" || savedData.dataType === "SETTLEMENT_BY_AMOUNT" || savedData.dataType === "SETTLEMENT_BY_REQUEST" ? (
                              <>
                                <label> Notes </label>
                                <TextareaAutosize
                                  cacheMeasurements
                                  value={ notes || "" }
                                  onChange={ (e) => this.setState({ notes: e.target.value }) }
                                  className="form-control"
                                />
                              </>
                            ) : (
                              <span/>
                            )}
                          </Column>
                          <Column
                            flexGrow={ 1 }
                            className="input-column fullwidth-button"
                          >
                            <CustomButton
                              title={ savedData.dataType !== "SETTLEMENT_BY_AMOUNT" ? 'Save' : 'Submit' }
                              type="button"
                              disabled={ lockButton || (savedData.dataType === "TOP_UP_PAYOUT_WALLET" && savedData.topUpRecordId !== undefined) }
                              onClick={ (e) => this.onSaveDataEntries() }
                            />
                          </Column>
                        </Row>
                      )}
                    </div>
                  </Panel.Body>
                </Panel>
              )}
              {showSecondPanel && savedData.dataType === "UPDATE_SETTLEMENT" && (
                <UpdateSettlementComponent
                  controls={ controls }
                  fieldsMap={ this.fieldsMap[savedData.dataType] }
                  filterFields={ (array, elem) => this.filterFields(array, savedData.dataType, elem) }
                  getFilteredStatuses={ (items, fieldElem) => this.getFilteredStatuses(items || this.state[fieldElem.items], savedData.settlementStatus) }
                  getValue={ (field, selectedFormField) => this.getValue(field, selectedFormField) }
                  lockButton={ lockButton }
                  onChange={ (value, fieldName, id) => this.onSettlementChange(value, fieldName, this.formsMap[savedData.dataType], id) }
                  onSave={ () => this.onSaveDataEntries() }
                  savedData={ savedData }
                  savedDataList={ savedDataList }
                  selectedForm={ selectedForm }
                  writePermission={ this.checkPageAccess("DATA_INPUT_WRITE") }
                />
              )}

              {(showSecondPanel && !loadingDataInput && savedData.dataType === "WITHHELD_FUNDS") && (
                <WithheldFundsComponent
                  editable={ true }
                  savedData={ savedData }
                  funds={ withheldFunds }
                  writePermission={ this.checkPageAccess("DATA_INPUT_WRITE") }
                  onTemporaryChange={ this.onWithheldTemporaryChange }
                  onChange={ this.onWithheldChange }
                  onSave={ () => this.onSaveDataEntries() }
                />
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
}

export default ManualInput;
