import React, { Component } from 'react';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { Redirect } from 'react-router-dom';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import Form from "@sm/core/Form";
import Multiselect from "@sm/components/custom/customMultiselect";
import MultiselectIp from "@modules/adminPanel/Multiselect";
import PromptModal from '@modules/generateReport/PromptModal';
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';
import Toggler from '@sm/components/custom/Toggler';

import '@assets/css/editClient.css';
import '@assets/css/pspTables.css';
import loadingIcon from '@assets/images/loading.png';
import PSPIconSingle from '@assets/images/psp_single_wl.png';
import VisibilityIcon from '@assets/images/visibilityIcon.png';
import VisibilityOffIcon from '@assets/images/visibilityOffIcon.png';

import { crudActions } from "@sm/services/crudActions";
import { trxExpirationTimeOptions } from '@sm/utils/trxExpirationTimeOptions';
import { addLogItem, sortItems } from "@sm/utils/utils";
const store =  require('@sm/reducers/index');

class ManagePSP extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
  }
  state = {
    pspForm: new Form({
      name: "",
      shortName: "",
      companyIds: [],
      paymentMethods: [],
      payoutMethods: [],
      currencies: [],
      approvalRequestId: null,
      depositTestUrl: "",
      payoutTestUrl: "",
      refundTestUrl: "",
      ipAddresses: [],
      depositLiveUrl: "",
      payoutLiveUrl: "",
      refundLiveUrl: "",
      expiredInMinutes: "",
      timeZone: "",
      walletType: ""
    }, [{
      name: "name",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "shortName",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "companyIds",
      type: "isArray",
      rules: {
        required: true,
        min: 1
      }
    }, {
      name: "depositTestUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "payoutTestUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "refundTestUrl",
      type: "isString",
      rules: {
        required: true
      }
    },{
      name: "ipAddresses",
      type: "isArray",
      rules: {
        required: true
      }
    }, {
      name: "depositLiveUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "payoutLiveUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "refundLiveUrl",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "approvalRequestId",
      type: "isNumber",
      rules: {
        required: false
      }
    }, {
      name: "paymentMethods",
      type: "isArray",
      rules: {
        required: false
      }
    }, {
      name: "payoutMethods",
      type: "isArray",
      rules: {
        required: false
      }
    }, {
      name: "currencies",
      type: "isArray",
      rules: {
        required: false
      }
    }, {
      name: "expiredInMinutes",
      type: "isNumber",
      rules: {
        required: false
      }
    }, {
      name: "timeZone",
      type: "isString",
      rules: {
        required: false
      }
    }, {
      name: "walletType",
      type: "isString",
      rules: {
        required: false
      }
    }, ]),

    accountForms: [],
    accountStatusLoader: [],
    accountPayoutStatusLoader: [],
    temporaryIP: "",
    defaultMethod: { value: "DEFAULT", label: "Default"},
    ipDropdown: {
      id: "ipAddresses",
      selectedId: "ipAddresses",
      header: "Whitelist IP",
      data: [],
      type: "input",
      deletable: true
    },

    enableRefund: false,
    updatePayoutByFile: false,
    statusApiDeposit: false,
    statusApiPayout: false,
    defaultCallbackUrl: false,
    depositTestUrl: "",
    payoutTestUrl:"",
    refundTestUrl: "",
    depositLiveUrl: "",
    payoutLiveUrl: "",
    refundLiveUrl: "",
    whiteLabels: [],
    paymentMethods: [],
    currencies: [],
    timeZones: [],
    providerWalletTypes: [],
    isLoading: true,
    isLoadingAccounts: false,
    isSubmitting: false,
    disabledCurrenciesWithMethod: [],
    disabledCurrenciesWithMethodPayout: [],
    fxPairs: [],
    access: [],
    roleId: "",
    additionalOne: "",
    additionalOneRequired: false,
    additionalTwo: "",
    additionalTwoRequired: false,
    additionalThree: "",
    additionalThreeRequired: false,
    additionalFour: "",
    additionalFourRequired: false,
    visibleAccountsIndex: [],

    defaultCompany: "",

    redirectToPsps: false,
    showAlert: false,
    alertType: "success",
    alertMessage: "",
    showPromptModal: false,
    editableField: "",
    editableFieldValue: "",
    editableForm: "",
    originalExpirationTime: ""
  };

  node = null;
  subscribeFunction = null;
  burgerNode = null;

  buysRefs = {};
  payoutsRefs = {};
  creditCardsRefs = {};

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access,
        roleId: storeState.roleId
      });
    }
    const pspId = this.props.pspId;
    const routeState = this.props.location && this.props.location.state;
    let approvalRequestId;
    if (routeState) {
      approvalRequestId = routeState.params.approvalRequestId;
    }

    if (approvalRequestId) {
      crudActions.get(`v1/approval/requests/${approvalRequestId}`).then(
        (requestData) => {
          if (requestData) {
            this.setState({
              pspForm: Object.assign(this.state.pspForm, {
                agentId: requestData.referredAgentId
              })
            });
          }
        }
      );
    }

    Promise.all([
      crudActions.get("v1/companies/labels"),
      crudActions.get('v1/adminpanel/lookups')
    ]).then(data => {
      const whiteLabels = data[0];
      const lookups = data[1];

      if (whiteLabels && lookups) {
        this.setState({
          whiteLabels: whiteLabels,
          paymentMethods: lookups.buyMethods,
          currencies: lookups.currency,
          timeZones: lookups.timeZones,
          providerWalletTypes: lookups.providerWalletTypes,
        });
      }

      if (pspId) {
        crudActions.get(`v1/psp/${pspId}`)
          .then(
            (psp) => {
              if (psp) {
                this.setState({
                  pspForm: Object.assign(this.state.pspForm, psp, {
                    expiredInMinutes: psp.expiredInMinutes || ''
                  }),
                  originalExpirationTime: psp.expiredInMinutes || '',
                  enableRefund: !!psp.enableRefund,
                  updatePayoutByFile: !!psp.updatePayoutByFile,
                  statusApiDeposit: !!psp.statusApiDeposit,
                  statusApiPayout: !!psp.statusApiPayout,
                  defaultCallbackUrl: !!psp.defaultCallbackUrl,
                  depositTestUrl: !!psp.depositTestUrl,
                  payoutTestUrl: !!psp.payoutTestUrl,
                  refundTestUrl: !!psp.refundTestUrl,
                  depositLiveUrl: !!psp.depositLiveUrl,
                  payoutLiveUrl: !!psp.payoutLiveUrl,
                  refundLiveUrl: !!psp.refundLiveUrl,
                  additionalOne: psp.additionalOne,
                  pspCurrencies: psp.currencies,
                  additionalOneRequired: !!psp.additionalOneRequired,
                  additionalTwo: psp.additionalTwo,
                  additionalTwoRequired: !!psp.additionalTwoRequired,
                  additionalThree: psp.additionalThree,
                  additionalThreeRequired: !!psp.additionalThreeRequired,
                  additionalFour: psp.additionalFour,
                  additionalFourRequired: !!psp.additionalFourRequired,
                  isLoading: false
                }, () => {
                  const selectedWhiteLabels = this.mapWhiteLabels();
                  if (selectedWhiteLabels.length > 0) {
                    this.onCompanySelect(selectedWhiteLabels[0]);
                  }
                });
              }
            }
          )
          .catch(
            (err) => {
              if (err && err.message) {
                this.setState({
                  showAlert: true,
                  alertType: "error",
                  alertMessage: err.message,
                  isLoading: false
                });
              }
            }
          );
      } else {
        this.setState({
          isLoading: false,
          isLoadingAccounts: false
        });
      }
    });
  };

  isFieldDisabled = () => {
    const viewOrEdit = this.props.viewOrEdit;

    if (!viewOrEdit) {
      return false;
    }

    return viewOrEdit === 1;
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: "",
      redirectToPsps: this.state.showAlert && this.state.alertType === "success"
    });
  };

  mapWhiteLabels = () => {
    const { whiteLabels, pspForm } = this.state;
    const selectedWhiteLabels = pspForm.companyIds;

    const mapped = selectedWhiteLabels.map(elem => {
      return whiteLabels.find(wl => wl.value === elem);
    });

    return sortItems(mapped);
  };

  mapAccountPaymentMethods = (currency, currentMethod) => {
    const disabledMethods = Array.isArray(this.state.disabledCurrenciesWithMethod[currency])
      ? this.state.disabledCurrenciesWithMethod[currency]
      : [];
    const methods = this.mapPaymentMethods();
    return this.addDefault(methods).filter(elem => !disabledMethods.includes(elem.value) || currentMethod === elem.value);
  };

  mapPaymentMethods = () => {
    const { paymentMethods, pspForm } = this.state;
    const selectedPaymentMethods = pspForm.paymentMethods;

    const mapped = selectedPaymentMethods.map(elem => {
      return paymentMethods.find(paymentMethod => paymentMethod.value === elem);
    });

    return sortItems(mapped);
  };

  mapAccountPayoutMethods = (currency, currentMethod) => {
    const disabledMethods = Array.isArray(this.state.disabledCurrenciesWithMethodPayout[currency])
      ? this.state.disabledCurrenciesWithMethodPayout[currency]
      : [];
    const methods = this.mapPayoutMethods();
    return this.addDefault(methods).filter(elem => !disabledMethods.includes(elem.value) || currentMethod === elem.value);
  };

  mapPayoutMethods = () => {
    const { paymentMethods, pspForm } = this.state;
    const selectedPayoutMethods = pspForm.payoutMethods;

    const mapped = selectedPayoutMethods.map(elem => {
      return paymentMethods.find(paymentMethod => paymentMethod.value === elem);
    });

    return sortItems(mapped);
  };

  filterAccountFxPairs = (currency) => {
    const { fxPairs } = this.state;
    return fxPairs.filter(pair => pair.split("/")[1] === currency).map(pair => ({ value: pair, label: pair }));
  };

  addDefault = (methods) => {
    methods.unshift(this.state.defaultMethod);
    return methods;
  };

  mapAccountCurrencies = (frozen, currency, payinMethod, payoutMethod) => {
    const { disabledCurrenciesWithMethod, disabledCurrenciesWithMethodPayout, pspCurrencies } = this.state;
    const { currencies } = this.state;

    const filteredDisabled = pspCurrencies.filter(elem => {
      const disabledMethods = disabledCurrenciesWithMethod[elem] || [];
      const disabledPayoutMethods = disabledCurrenciesWithMethodPayout[elem] || [];
      const isEnabled = !disabledMethods.includes(payinMethod) && !disabledPayoutMethods.includes(payoutMethod);
      return (isEnabled || this.isCurrencyDisabled(frozen, currency, payinMethod, payoutMethod));
    });
    return filteredDisabled.sort().map(elem => {
      return currencies.find(item => item.value === elem);
    });
  };

  mapCurrencies = (selectedCurrencies) => {
    const { currencies } = this.state;

    const mapped = selectedCurrencies.map(elem => {
      return currencies.find(item => item.value === elem);
    });

    return sortItems(mapped);
  };

  onValueChange = (event, fieldName) => {
    let { pspForm } = this.state;
    const { originalExpirationTime } = this.state;

    if (event.value) {
      pspForm = Object.assign(pspForm, {
        [fieldName]: event.value
      });
    } else if (event.target) {
      const value = event.target.value;
      if (fieldName === "expiredInMinutes" && value !== originalExpirationTime.toString() && originalExpirationTime) {
        this.setState({
          showPromptModal: true,
          editableField: fieldName,
          editableFieldValue: value,
          editableForm: "pspForm"
        });
        return;
      }
      pspForm = Object.assign(pspForm, {
        [fieldName]: value
      });
    } else {
      pspForm = Object.assign(pspForm, {
        [fieldName]: event.map(elem => elem.value)
      });
    }

    pspForm.isFormValid();

    this.setState({
      pspForm
    });
  };

  submitPSPSettings = () => {
    const { pspForm, enableRefund, updatePayoutByFile, statusApiPayout, defaultCallbackUrl, statusApiDeposit, temporaryIP } = this.state;
    const { pspId, viewOrEdit } = this.props;
    const routeState = this.props.location && this.props.location.state;

    const isError = this.multiselectRef.current.inputCheckError();
    if (isError) {
      return;
    }
    if (temporaryIP) {
      pspForm.ipAddresses.push(temporaryIP);
    }
    let approvalRequestId;
    if (routeState) {
      approvalRequestId = routeState.params.approvalRequestId;
    }

    const isFormValid = pspForm.isFormValid();
    this.setState({
      pspForm
    });

    if (isFormValid) {
      this.setState({
        isLoading: true,
        isSubmitting: true
      });
      const data = Object.assign(pspForm.data(), { enableRefund, updatePayoutByFile, statusApiPayout, defaultCallbackUrl, statusApiDeposit });
      (pspId ? crudActions.put(`v1/psp`, Object.assign(data, {
        id: pspId
      })) : crudActions.post(`v1/psp`, Object.assign(data, {
        approvalRequestId: approvalRequestId
      }))).then(
        () => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: `Provider successfully ${viewOrEdit === 2 ? 'updated' : 'saved'}.`,
            isLoading: false,
            pspCurrencies: pspForm.currencies,
            isSubmitting: false
          });
        }
      ).catch(
        (err) => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoading: false,
              pspCurrencies: pspForm.currencies,
              isSubmitting: false
            });
          }
        }
      );
    } else {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Some fields have errors. Please, fix them."
      });
    }
  };

  setRef = (ref, rowId, columnName, mapName) => {
    if (!this[mapName][rowId]) {
      this[mapName][rowId] = {};
    }

    this[mapName][rowId][columnName] = ref;
  };

  onTableDataClick = (value, columnName, rowId, clickMapName, mapName) => {
    const viewOrEdit = this.props.viewOrEdit;
    const clickMap = this.state[clickMapName];
    if ((value !== 0 && !value) || viewOrEdit === 1) {
      return false;
    }

    clickMap[rowId][columnName] = !clickMap[rowId][columnName];

    this.setState({
      [clickMapName]: clickMap
    }, () => {
      const input = this[mapName][rowId][columnName];
      if (input) {
        input.focus();
      }
    });
  };

  onKeyPress = (event, value, rowId, fieldName, clickMap, refsMap) => {
    const keyPressed = event.charCode;
    if (keyPressed === 13) {
      this.onTableDataClick(value, rowId, fieldName, clickMap, refsMap);
    }
  };

  onChangeGeneralSettings = (event, columnName, tableName, rowId) => {
    const table = this.state[tableName];
    const editRow = table.rows.find(elem => elem.id === rowId);
    if (editRow) {
      editRow.defaults[columnName] = event.target.value ? parseFloat(event.target.value) : "";
    }

    this.setState({
      [tableName]: table
    });
  };

  getValue = (value) => {
    if (value === 0) {
      return 0;
    } else if (!value) {
      return "";
    }

    return value;
  };

  onCompanySelect = (value) => {
    const { pspId } = this.props;

    this.setState({
      defaultCompany: value.value,
      isLoadingAccounts: true
    }, () => { 
      crudActions.get(`v1/psp/accounts/${pspId}?companyId=${value.value}`)
        .then(
          (response) => {
            const accounts = response.pspAccounts;
            if (accounts) {
              this.setState({
                isLoadingAccounts: false,
                disabledCurrenciesWithMethod: response.disabledCurrenciesWithMethod,
                disabledCurrenciesWithMethodPayout: response.disabledCurrenciesWithMethodPayout,
                fxPairs: response.fxPairs,
                accountForms: []
              }, () => {
                const accountsList = [];
                accounts.length && accounts.forEach(account => {
                  const fields = {
                    id: account.id,
                    additionalOne: account.additionalOne || "",
                    additionalTwo: account.additionalTwo || "",
                    additionalThree: account.additionalThree || "",
                    additionalFour: account.additionalFour || "",
                    buyRate: account.buyRate,
                    payoutRate: account.payoutRate,
                    tplusX: account.tplusX,
                    maxAmountPayin: account.maxAmountPayin || "",
                    minAmountPayin: account.minAmountPayin || "",
                    maxAmountPayout: account.maxAmountPayout || "",
                    minAmountPayout: account.minAmountPayout || "",
                    currency: account.currency || "",
                    payinMethod: account.payinMethod || "",
                    payoutMethod: account.payoutMethod || "",
                    fxPair: account.fxPair || "",
                    name: account.name,
                    enabled: account.enabled,
                    payoutEnabled: account.payoutEnabled,
                    pspId: pspId,
                    companyId: account.companyId,
                    frozen: false
                  };
                  const fieldsRules = [{
                    name: "additionalOne",
                    type: "isString",
                    rules: {
                      required: this.state.additionalOneRequired
                    }
                  }, {
                    name: "additionalTwo",
                    type: "isString",
                    rules: {
                      required: this.state.additionalTwoRequired
                    }
                  }, {
                    name: "additionalThree",
                    type: "isString",
                    rules: {
                      required: this.state.additionalThreeRequired
                    }
                  }, {
                    name: "additionalFour",
                    type: "isString",
                    rules: {
                      required: this.state.additionalFourRequired
                    }
                  }, {
                    name: "name",
                    type: "isString",
                    rules: {
                      required: true
                    }
                  }, {
                    name: "buyRate",
                    type: "isNumber",
                    rules: {
                      required: true
                    }
                  }, {
                    name: "payoutRate",
                    type: "isNumber",
                    rules: {
                      required: true
                    }
                  }, {
                    name: "tplusX",
                    type: "isNumber",
                    rules: {
                      required: true
                    }
                  }, {
                    name: "minAmountPayin",
                    type: "isNumber",
                  }, {
                    name: "maxAmountPayin",
                    type: "isNumber",
                  }, {
                    name: "minAmountPayout",
                    type: "isNumber",
                  }, {
                    name: "maxAmountPayout",
                    type: "isNumber",
                  }, {
                    name: "buyRate",
                    type: "isNumber",
                    rules: {
                      required: true
                    }
                  }, {
                    name: "payoutRate",
                    type: "isNumber",
                    rules: {
                      required: true
                    }
                  }, {
                    name: "tPlusX",
                    type: "isNumber",
                    rules: {
                      required: true
                    }
                  }, {
                    name: "currency",
                    type: "isString",
                    rules: {
                      required: true
                    }
                  },{
                    name: "payinMethod",
                    type: "isString",
                    rules: {
                      required: true
                    }
                  },{
                    name: "payoutMethod",
                    type: "isString",
                    rules: {
                      required: true
                    }
                  },{
                    name: "fxPair",
                    type: "isString",
                    rules: {
                      required: true
                    }
                  },];
                  accountsList.push(new Form(fields, fieldsRules));
                });
                this.setState({
                  accountForms: accountsList
                });
              });
            }
          }
        ).catch(
          (err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
                isLoadingAccounts: false
              });
            }
          }
        );
    });
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    
    if (!foundPermission) {
      return false;
    }
 
    return foundPermission.state;
  };

  toggleInputView = (e, index) => {
    let { visibleAccountsIndex } = this.state;
    e.preventDefault();
    if (visibleAccountsIndex.includes(index)) {
      visibleAccountsIndex = visibleAccountsIndex.filter(i => i !== index);
    } else {
      visibleAccountsIndex.push(index);
    }
    this.setState({
      visibleAccountsIndex
    });
  };

  checkInputVisibility = (index) => {
    const { visibleAccountsIndex } = this.state;
    return visibleAccountsIndex.includes(index);
  };

  onAccountItemChange = (event, index, field) => {
    const { accountForms } = this.state;

    const isNumber = accountForms[index].fieldRules.find(rule => rule.name === field)?.type === "isNumber";

    if (typeof event === "boolean") {
      accountForms[index][field] = event;
    } else if (field === 'currency') {
      accountForms[index][field] = event.value;
      accountForms[index].enabled = !this.isPayinDisabled(accountForms[index].frozen, accountForms[index].currency, accountForms[index].payinMethod);
      accountForms[index].payoutEnabled = !this.isPayoutDisabled(accountForms[index].frozen, accountForms[index].currency, accountForms[index].payoutMethod);
      accountForms[index].fxPair = "";
    } else if (event.target) {
      accountForms[index][field] = isNumber && !isNaN(parseFloat(event.target.value)) ? parseFloat(event.target.value) : event.target.value;
    } else {
      accountForms[index][field] = event.value;
    }

    this.setState({
      accountForms
    });
  };

  onToggleAccountItemStatus = (event, index, field, accountId) => {
    let url = "v1/psp/accounts";
    if (field === "payoutEnabled") {
      url += "/payout";
    }
    const { accountStatusLoader, accountPayoutStatusLoader } = this.state;
    const arrayName = field === "enabled" ? "accountStatusLoader" : "accountPayoutStatusLoader";
    const idsArray = field === "enabled" ? accountStatusLoader : accountPayoutStatusLoader;
    
    if (accountId) {
      idsArray.push(index);
      
      const { accountForms, defaultCompany } = this.state;
      accountForms.forEach(element => {
        element.frozen = true;
      });
      this.setState({
        [arrayName]: idsArray,
        accountForms: accountForms
      });

      const changes = addLogItem(
        [],
        "PROVIDER_ACCOUNT_UPDATE",
        accountForms[index][field] ? "on" : "off",
        event ? "on" : "off",
        field,
        accountId,
        { providerId: this.props.pspId }
      );

      crudActions.patch(url, {
        [field]: event,
        id: accountId,
        companyId: defaultCompany,
        changes
      }).then(response => {
        this.onAccountItemChange(event, index, field);
        accountForms.forEach(element => {
          element.frozen = false;
        });
        this.setState({
          [arrayName]: this.state[arrayName].filter(i => i !== index),
          accountForms: accountForms
        });
      })
        .catch(
          err => {
            accountForms.forEach(element => {
              element.frozen = false;
            });
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
                [arrayName]: this.state[arrayName].filter(i => i !== index),
                accountForms: accountForms
              });
            }
          }
        );
    } else {
      this.onAccountItemChange(event, index, field);
    }
  };

  onAddAccountItem = () => {
    const { accountForms, defaultMethod } = this.state;
    accountForms.push(new Form({
      id: "",
      additionalOne: "",
      additionalTwo: "",
      additionalThree: "",
      additionalFour: "",
      buyRate: "",
      payoutRate: "",
      tplusX: "",
      maxAmountPayin: "",
      minAmountPayin: "",
      maxAmountPayout: "",
      minAmountPayout: "",
      currency: "",
      payinMethod: defaultMethod.value,
      payoutMethod: defaultMethod.value,
      fxPair: "",
      name: "",
      enabled: false,
      payoutEnabled: false,
      pspId: this.props.pspId,
      companyId: this.state.defaultCompany
    }, [{
      name: "additionalOne",
      type: "isString",
      rules: {
        required: this.state.additionalOneRequired
      }
    }, {
      name: "additionalTwo",
      type: "isString",
      rules: {
        required: this.state.additionalTwoRequired
      }
    }, {
      name: "additionalThree",
      type: "isString",
      rules: {
        required: this.state.additionalThreeRequired
      }
    }, {
      name: "additionalFour",
      type: "isString",
      rules: {
        required: this.state.additionalFourRequired
      }
    }, {
      name: "name",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "buyRate",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "payoutRate",
      type: "isNumber",
      rules: {
        required: true
      }
    }, {
      name: "tplusX",
      type: "isNumber",
      rules: {
        required: true
      }
    },{
      name: "minAmountPayin",
      type: "isNumber",
    },{
      name: "maxAmountPayin",
      type: "isNumber",
    },{
      name: "minAmountPayout",
      type: "isNumber",
    },{
      name: "maxAmountPayout",
      type: "isNumber",
    }, {
      name: "currency",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "payinMethod",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "payoutMethod",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "fxPair",
      type: "isString",
      rules: {
        required: true
      }
    },]));
    this.setState({
      accountForms
    });
  };

  onDeleteAccountItem = (index) => {
    const { accountForms } = this.state;

    accountForms.splice(index, 1);
    this.setState({
      accountForms
    });
  };

  saveProviderAccounts = () => {
    const { accountForms } = this.state;
    const { pspId } = this.props;
    let isFormsValid = true;

    accountForms.forEach(form => {
      form.isFormValid();
      if (!form.isFormValid()) {
        isFormsValid = false;
      }
    });

    this.setState({
      accountForms
    });

    if (isFormsValid) {
      this.setState({
        isLoadingAccounts: true
      });
      const data = [];
      accountForms.forEach(form => {
        const formData = form.data();
        if (!formData.id) {
          delete formData.id;
        }
        data.push(formData);
      });

      crudActions.post(`v1/psp/accounts/${pspId}`, data)
        .then(response => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: response.message || "Provider accounts successfully saved.",
            isLoadingAccounts: false
          });
        })
        .catch(
          (err) => {
            if (err && err.message) {
              this.setState({
                showAlert: true,
                alertType: "error",
                alertMessage: err.message,
                isLoadingAccounts: false
              });
            }
          }
        );
    }
    
  };

  mapSelectedIps = () => {
    const { pspForm  } = this.state;
    return pspForm.ipAddresses.map(selectedItem => {
      return {
        value: selectedItem,
        label: selectedItem
      };
    });
  };

  onTemporaryChange = (id, value) => {
    this.setState({
      temporaryIP: value
    });
  };

  onMultiselectChange = (id, value) => {
    const { temporaryIP } = this.state;
    let { pspForm } = this.state;
    pspForm = Object.assign(pspForm, {
      [id]: value
    });

    this.setState({
      pspForm,
      ...(pspForm[id].includes(temporaryIP) && { temporaryIP: "" })
    });
  };

  isDisabled = (frozen, currency, method, disabledCurrenciesWithMethod) => {
    const currencies = Object.keys(disabledCurrenciesWithMethod);
    return frozen || (currencies.includes(currency) && disabledCurrenciesWithMethod[currency].includes(method));
  };

  isPayinDisabled = (frozen, currency, method) => {
    const { disabledCurrenciesWithMethod } = this.state;
    return this.isDisabled(frozen, currency, method, disabledCurrenciesWithMethod);
  };

  isPayoutDisabled = (frozen, currency, method) => {
    const { disabledCurrenciesWithMethodPayout } = this.state;
    return this.isDisabled(frozen, currency, method, disabledCurrenciesWithMethodPayout);
  };

  isCurrencyDisabled = (frozen, currency, method, methodPayout) => {
    return this.isPayinDisabled(frozen, currency, method) || this.isPayoutDisabled(frozen, currency, methodPayout);
  };

  handleConfirmPromptModal = () => {
    const { editableField, editableFieldValue, editableForm } = this.state;
    let currentForm = this.state[editableForm];
    currentForm = Object.assign(currentForm, {
      [editableField]: editableFieldValue
    });
    this.setState({
      [editableForm]: currentForm,
      showPromptModal: false,
      editableField: "",
      editableFieldValue: "",
      editableForm: ""
    });
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      editableField: "",
      editableFieldValue: "",
      editableForm: ""
    });
  };

  render() {
    const { pspId, viewOrEdit } = this.props;
    const {
      accountForms,
      accountStatusLoader,
      accountPayoutStatusLoader,
      additionalOne,
      additionalOneRequired,
      additionalTwo,
      additionalTwoRequired,
      additionalThree,
      additionalThreeRequired,
      additionalFour,
      additionalFourRequired,
      alertMessage,
      alertType,
      currencies,
      defaultCallbackUrl,
      defaultCompany,
      enableRefund,
      ipDropdown,
      isLoading,
      isLoadingAccounts,
      isSubmitting,
      paymentMethods,
      providerWalletTypes,
      pspForm,
      showAlert,
      showPromptModal,
      statusApiDeposit,
      statusApiPayout,
      timeZones,
      redirectToPsps,
      updatePayoutByFile,
      whiteLabels
    } = this.state;

    const userRole = store.default.getState().authReducer.roleId;
    const isAbleToEditAccounts = userRole === "MASTER_TECH" || userRole === "MASTER_ADMIN" || userRole === "WL_ADMIN";

    if (redirectToPsps) {
      return <Redirect to={ '/providers' }/>;
    }
    return <Row flexGrow={ 1 } className="module apidata editClient psps" vertical='start'>
      <Column flexGrow={ 1 }>
        <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
          <Column>
            <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
              <img src={ PSPIconSingle } alt="" style={ { marginRight: 10 } }/>
              { !viewOrEdit ? `Add Provider` : viewOrEdit === 1 ? `View Provider` : `Edit Provider` }
            </Row>
          </Column>
        </Row>
        <Row flexGrow={ 1 } horizontal='start' wrap vertical='start'>
          <Column flexGrow={ 1 } vertical='start' className="panel-block">
            {userRole === "MASTER_TECH" && (
              <Panel>
                <Panel.Heading>
                  PROVIDER SETTINGS
                </Panel.Heading>
                <Panel.Body>
                  { isLoading ? (
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Labels </label>
                          <Multiselect
                            isError={ pspForm.errors.has('companyIds') }
                            selectedItems={ this.mapWhiteLabels() }
                            disabled={ this.isFieldDisabled() }
                            items={ whiteLabels }
                            type={ "companyIds" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Name </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('name') ? 'error-field' : "") }
                            value={ pspForm.name || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'name') }/>
                          { pspForm.errors.has('name') && <span className="error-message"> {pspForm.errors.get("name")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Short name </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('shortName') ? 'error-field' : "") }
                            value={ pspForm.shortName || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'shortName') }/>
                          { pspForm.errors.has('shortName') && <span className="error-message"> {pspForm.errors.get("shortName")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Payment Methods </label>
                          <Multiselect
                            isError={ pspForm.errors.has('paymentMethods') }
                            selectedItems={ this.mapPaymentMethods() }
                            disabled={ this.isFieldDisabled() }
                            items={ paymentMethods }
                            type={ "paymentMethods" }
                            onChange={ this.onValueChange }/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Payout Methods </label>
                          <Multiselect
                            isError={ pspForm.errors.has('payoutMethods') }
                            selectedItems={ this.mapPayoutMethods() }
                            disabled={ this.isFieldDisabled() }
                            items={ paymentMethods }
                            type={ "payoutMethods" }
                            onChange={ this.onValueChange }/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Demo Deposit URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('depositTestUrl') ? 'error-field' : "") }
                            value={ pspForm.depositTestUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'depositTestUrl') }/>
                          { pspForm.errors.has('depositTestUrl') && <span className="error-message"> {pspForm.errors.get("depositTestUrl")}  </span> }
                        </Column>
                      
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Live Deposit URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('depositLiveUrl') ? 'error-field' : "") }
                            value={ pspForm.depositLiveUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'depositLiveUrl') }/>
                          { pspForm.errors.has('depositLiveUrl') && <span className="error-message"> {pspForm.errors.get("depositLiveUrl")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Demo Payout URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('payoutTestUrl') ? 'error-field' : "") }
                            value={ pspForm.payoutTestUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'payoutTestUrl') }/>
                          { pspForm.errors.has('payoutTestUrl') && <span className="error-message"> {pspForm.errors.get("payoutTestUrl")}  </span> }
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Live Payout URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('payoutLiveUrl') ? 'error-field' : "") }
                            value={ pspForm.payoutLiveUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'payoutLiveUrl') }/>
                          { pspForm.errors.has('payoutLiveUrl') && <span className="error-message"> {pspForm.errors.get("payoutLiveUrl")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Demo Refund URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('refundTestUrl') ? 'error-field' : "") }
                            value={ pspForm.refundTestUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'refundTestUrl') }/>
                          { pspForm.errors.has('refundTestUrl') && <span className="error-message"> {pspForm.errors.get("refundTestUrl")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Live Refund URL </label>
                          <input
                            className={ "form-control " + (pspForm.errors.has('refundLiveUrl') ? 'error-field' : "") }
                            value={ pspForm.refundLiveUrl || '' }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'refundLiveUrl') }/>
                          { pspForm.errors.has('refundLiveUrl') && <span className="error-message"> {pspForm.errors.get("refundLiveUrl")}  </span> }
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Currencies </label>
                          <Multiselect
                            isError={ pspForm.errors.has('currencies') }
                            selectedItems={ this.mapCurrencies(pspForm.currencies) }
                            disabled={ this.isFieldDisabled() }
                            items={ currencies }
                            type={ "currencies" }
                            onChange={ this.onValueChange }/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Transaction expiration time </label>
                          <Select
                            id="expiredInMinutes"
                            name="expiredInMinutes"
                            value={ pspForm.expiredInMinutes }
                            required={ false }
                            clearable={ false }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'expiredInMinutes') }
                            options={ trxExpirationTimeOptions }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Time Zone </label>
                          <Select
                            id="timeZone"
                            name="timeZone"
                            value={ pspForm.timeZone || "" }
                            required={ false }
                            clearable={ false }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'timeZone') }
                            options={ timeZones }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Wallet Type </label>
                          <Select
                            id="walletType"
                            name="walletType"
                            value={ pspForm.walletType || "" }
                            required={ false }
                            clearable={ false }
                            disabled={ this.isFieldDisabled() }
                            onChange={ (event) => this.onValueChange(event, 'walletType') }
                            options={ providerWalletTypes }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                            <div className="styled-input-single">
                              <input
                                id="updatePayoutByFile"
                                type="checkbox"
                                disabled={ this.isFieldDisabled() }
                                checked={ updatePayoutByFile }
                                value={ updatePayoutByFile }
                                onChange={ () => this.setState({ updatePayoutByFile: !updatePayoutByFile }) }
                              />
                              <label style={ { fontWeight: "normal" } } htmlFor="updatePayoutByFile">
                                Update Payout by File
                              </label>
                            </div>
                          </Row>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                            <div className="styled-input-single">
                              <input
                                id="enableRefund"
                                type="checkbox"
                                disabled={ this.isFieldDisabled() }
                                checked={ enableRefund }
                                value={ enableRefund }
                                onChange={ () => this.setState({ enableRefund: !enableRefund }) }
                              />
                              <label style={ { fontWeight: "normal" } } htmlFor="enableRefund">
                                Enable Refund
                              </label>
                            </div>
                          </Row>
                        </Column>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                            <div className="styled-input-single">
                              <input
                                id="statusApiDeposit"
                                type="checkbox"
                                disabled={ this.isFieldDisabled() }
                                checked={ statusApiDeposit }
                                value={ statusApiDeposit }
                                onChange={ () => this.setState({ statusApiDeposit: !statusApiDeposit }) }
                              />
                              <label style={ { fontWeight: "normal" } } htmlFor="statusApiDeposit">
                                Enable Status Api  Deposit
                              </label>
                            </div>
                          </Row>
                        </Column>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                            <div className="styled-input-single">
                              <input
                                id="statusApiPayout"
                                type="checkbox"
                                disabled={ this.isFieldDisabled() }
                                checked={ statusApiPayout } value={ statusApiPayout }
                                onChange={ () => this.setState({ statusApiPayout: !statusApiPayout }) }
                              />
                              <label style={ { fontWeight: "normal" } } htmlFor="statusApiPayout">
                                Enable Status Api Payout
                              </label>
                            </div>
                          </Row>
                        </Column>
                        <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column">
                          <Row className="styled-input--square" wrap horizontal='start' vertical='end' style={ { width: '100%' } }>
                            <div className="styled-input-single">
                              <input
                                id="defaultCallbackUrl"
                                type="checkbox"
                                disabled={ this.isFieldDisabled() }
                                checked={ defaultCallbackUrl } value={ defaultCallbackUrl }
                                onChange={ () => this.setState({ defaultCallbackUrl: !defaultCallbackUrl }) }
                              />
                              <label style={ { fontWeight: "normal" } } htmlFor="defaultCallbackUrl">
                                Enable Default Callback URL
                              </label>
                            </div>
                          </Row>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                        <Column key={ ipDropdown.id } flexGrow={ 1 } alignSelf='start' style={ { margin: '10px' } } className="input-column">
                          <MultiselectIp 
                            id={ ipDropdown.id }
                            selectedId={ ipDropdown.selectedId }
                            header={ ipDropdown.header }
                            items={ ipDropdown.data }
                            type={ ipDropdown.type }
                            update={ true }
                            selectedItems={ this.mapSelectedIps() }
                            pattern={ "(?:^|(?<=\\s))(\\d{1,3}\\.){3}\\d{1,3}(?=\\s|$)" }
                            onChange={ this.onMultiselectChange }
                            deletable={ ipDropdown.deletable }
                            onTemporaryChange={ this.onTemporaryChange }
                            ref={ this.multiselectRef }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' className="input-column">
                          { viewOrEdit !== 1 ? (
                            <CustomButton
                              title="Save"
                              type="submit"
                              disabled={ isSubmitting }
                              onClick={ this.submitPSPSettings }
                            />
                          ) : (
                            <span/>
                          )}
                        </Column>
                      </Row>
                    </div>
                  )}
                </Panel.Body>
              </Panel>
            )}
  
            {this.checkPageAccess('PSP_ACCOUNT_EDIT') && pspId && (
              <Panel>
                <Panel.Heading>
                  PROVIDER ACCOUNTS
                </Panel.Heading>
                <Panel.Body>
                  {isLoadingAccounts ? (
                    <div style={ { width: "100%", height: "200px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  ) : (
                    <>
                      <Row flexGrow={ 1 } wrap horizontal='start' vertical='start' alignSelf='start'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Label </label>
                          <Select
                            id="defaultCompany"
                            name="defaultCompany"
                            value={ defaultCompany || "" }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onCompanySelect(value) }
                            options={ whiteLabels.filter(item => pspForm.companyIds.includes(item.value)) }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          {userRole !== "MASTER_TECH" ? (
                            <>
                              <label> Provider Name </label>
                              <input
                                className="form-control"
                                value={ pspForm.name || '' }
                                disabled={ true }
                              />
                            </>
                          ) : (
                            <span/>
                          )}
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column empty-column">
                          <span/>
                        </Column>
                      </Row>
                      <hr/>
                      <div className="panel-content provider-accounts">
                        { accountForms.length > 0 && accountForms.map((accountForm, index) =>
                          <Row
                            key={ index }
                            flexGrow={ 1 }
                            horizontal='start'
                            wrap
                            vertical='end'
                            style={ { width: '100%' } }
                            className="account-row"
                          >
                            <Column vertical='center' style={ {width: isAbleToEditAccounts ? true : '100%'} } className="account-box">
                              <Row
                                flexGrow={ 1 }
                                horizontal='start'
                                wrap
                                vertical='end'
                                style={ { width: '100%'} }
                              >
                                <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column account">
                                  <label> Account Name </label>
                                  <input
                                    className={ "form-control " + (accountForm.errors.has('name') ? 'error-field' : "") }
                                    value={ accountForm.name || '' }
                                    disabled={ this.isFieldDisabled() }
                                    onChange={ (event) => this.onAccountItemChange(event, index, 'name') }
                                  />
                                </Column>
                                { isAbleToEditAccounts && additionalOneRequired && (
                                  <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column account">
                                    <label> { additionalOne } </label>
                                    <input
                                      className={ "form-control " + (accountForm.errors.has('additionalOne') ? 'error-field' : "") }
                                      value={ accountForm.additionalOne || '' }
                                      disabled={ this.isFieldDisabled() }
                                      onChange={ (event) => this.onAccountItemChange(event, index, 'additionalOne') }
                                    />
                                  </Column>
                                )}
                                { isAbleToEditAccounts && additionalTwoRequired && (
                                  <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column account">
                                    <label> { additionalTwo } </label>
                                    <input
                                      type={ this.checkInputVisibility(index) ? "text" : "password" }
                                      className={ "form-control icon " + (accountForm.errors.has('additionalTwo') ? 'error-field' : "") }
                                      value={ accountForm.additionalTwo || '' }
                                      disabled={ this.isFieldDisabled() }
                                      onChange={ (event) => this.onAccountItemChange(event, index, 'additionalTwo') }
                                    />
                                    <button
                                      type="button"
                                      className="toggle"
                                      onClick={ (e) => this.toggleInputView(e, index) }
                                    >
                                      <img
                                        src={ this.checkInputVisibility(index) ? VisibilityIcon : VisibilityOffIcon }
                                        alt={ this.checkInputVisibility(index) ? "show" : "hide" }
                                        className="visibilityIcon"
                                      />
                                    </button>
                                  </Column>
                                )}
                                { isAbleToEditAccounts && additionalThreeRequired && (
                                  <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column account">
                                    <label> { additionalThree } </label>
                                    <input
                                      className={ "form-control " + (accountForm.errors.has('additionalThree') ? 'error-field' : "") }
                                      value={ accountForm.additionalThree || '' }
                                      disabled={ this.isFieldDisabled() }
                                      onChange={ (event) => this.onAccountItemChange(event, index, 'additionalThree') }
                                    />
                                  </Column>
                                )}
                                { isAbleToEditAccounts && additionalFourRequired && (
                                  <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column account">
                                    <label> { additionalFour } </label>
                                    <input
                                      className={ "form-control " + (accountForm.errors.has('additionalFour') ? 'error-field' : "") }
                                      value={ accountForm.additionalFour || '' }
                                      disabled={ this.isFieldDisabled() }
                                      onChange={ (event) => this.onAccountItemChange(event, index, 'additionalFour') }
                                    />
                                  </Column>
                                )}
                                { !isAbleToEditAccounts && (
                                  <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column account">
                                    <label> Provider </label>
                                    <input
                                      className="form-control"
                                      value={ pspForm.name || '' }
                                      disabled={ true }
                                      onChange={ (event) => this.onValueChange(event, 'name') }
                                    />
                                  </Column>
                                )}
                                <Column vertical='start' alignSelf='start' className="input-column toggle">
                                  <Row horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                                    <Column vertical='center' style={ { marginLeft: '25px' } }>
                                      <label style={ { marginBottom: "10px" } }> Payin Status </label>
                                      {accountStatusLoader.length && accountStatusLoader.indexOf(index) > -1 ?
                                        <div className="loading-wrapper">
                                          <img src={ loadingIcon } alt="loading" />
                                        </div>
                                        : (
                                          <Toggler
                                            active={ accountForm.enabled }
                                            disabled={ this.isPayinDisabled(accountForm.frozen, accountForm.currency, accountForm.payinMethod) }
                                            onClick={ (event) => this.onToggleAccountItemStatus(event, index, 'enabled', accountForm.id) }
                                          />
                                        )}

                                    </Column>
                                    <Column vertical='center' style={ { marginLeft: '25px' } }>
                                      <label style={ { marginBottom: "10px" } }> Payout Status </label>
                                      {accountPayoutStatusLoader.length && accountPayoutStatusLoader.indexOf(index) > -1 ?
                                        <div className="loading-wrapper">
                                          <img src={ loadingIcon } alt="loading" />
                                        </div>
                                        : (
                                          <Toggler
                                            active={ accountForm.payoutEnabled }
                                            disabled={ this.isPayoutDisabled(accountForm.frozen, accountForm.currency, accountForm.payoutMethod) }
                                            onClick={ (event) => this.onToggleAccountItemStatus(event, index, 'payoutEnabled', accountForm.id) }
                                          />
                                        )}

                                    </Column>
                                  </Row>
                                </Column>
                              </Row>
                              <Row
                                flexGrow={ 1 }
                                horizontal='start'
                                wrap
                                vertical='end'
                                style={ { width: '100%' } }
                              >
                                <Column flexGrow={ 1 }>
                                  <Row horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                                    <Column vertical='center'  className="input-column input-column-short">
                                      <label> Buy Rate </label>
                                      <input
                                        className={ "form-control input-short " + (accountForm.errors.has('buyRate') ? 'error-field' : "") }
                                        type="number"
                                        value={ accountForm.buyRate }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'buyRate')  }
                                      />
                                    </Column>
                                    <Column vertical='center' className="input-column input-column-short">
                                      <label> Payout Rate </label>
                                      <input
                                        className={ "form-control input-short " + (accountForm.errors.has('payoutRate') ? 'error-field' : "") }
                                        type="number"
                                        value={ accountForm.payoutRate }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'payoutRate') }
                                      />
                                    </Column>
                                    <Column vertical='center' className="input-column input-column-short">
                                      <label> T + X </label>
                                      <input
                                        className={ "form-control input-short " + (accountForm.errors.has('tplusX') ? 'error-field' : "") }
                                        type="number"
                                        value={ accountForm.tplusX }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'tplusX') }
                                      />
                                    </Column>
                                    <Column vertical='center' className="input-column input-column-short">
                                      <label> Min Payin </label>
                                      <input
                                        className={ "form-control input-short " + (accountForm.errors.has('minAmountPayin') ? 'error-field' : "") }
                                        type="number"
                                        value={ accountForm.minAmountPayin }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'minAmountPayin') }
                                      />
                                    </Column>
                                    <Column vertical='center' className="input-column input-column-short">
                                      <label> Max Payin </label>
                                      <input
                                        className={ "form-control input-short " + (accountForm.errors.has('maxAmountPayin') ? 'error-field' : "") }
                                        type="number"
                                        value={ accountForm.maxAmountPayin }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'maxAmountPayin') }
                                      />
                                    </Column>
                                    <Column vertical='center' className="input-column input-column-short">
                                      <label> Min Payout </label>
                                      <input
                                        className={ "form-control input-short " + (accountForm.errors.has('minAmountPayout') ? 'error-field' : "") }
                                        type="number"
                                        value={ accountForm.minAmountPayout }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'minAmountPayout') }
                                      />
                                    </Column>
                                    <Column vertical='center' className="input-column input-column-short">
                                      <label> Max Payout </label>
                                      <input
                                        className={ "form-control input-short " + (accountForm.errors.has('maxAmountPayout') ? 'error-field' : "") }
                                        type="number"
                                        value={ accountForm.maxAmountPayout }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'maxAmountPayout') }
                                      />
                                    </Column>
                                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column input-column-short">
                                      <label> Currency </label>
                                      <Select
                                        id="currency"
                                        name="currency"
                                        value={ accountForm.currency || "" }
                                        className={ " " + (accountForm.errors.has('currency') ? 'error-field' : "") }
                                        disabled={ this.isCurrencyDisabled(accountForm.frozen, accountForm.currency, accountForm.payinMethod, accountForm.payoutMethod) }
                                        required={ true }
                                        clearable={ false }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'currency') }
                                        options={ this.mapAccountCurrencies(accountForm.frozen, accountForm.currency, accountForm.payinMethod, accountForm.payoutMethod) }
                                      />
                                    </Column>
                                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column input-column-short">
                                      <label> Payin Method </label>
                                      <Select
                                        id="payinMethod"
                                        name="payinMethod"
                                        value={ accountForm.payinMethod || "" }
                                        className={ " " + (accountForm.errors.has('payinMethod') ? 'error-field' : "") }
                                        disabled={ this.isPayinDisabled(accountForm.frozen, accountForm.currency, accountForm.payinMethod) }
                                        required={ true }
                                        clearable={ false }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'payinMethod') }
                                        options={ this.mapAccountPaymentMethods(accountForm.currency, accountForm.payinMethod) }
                                      />
                                    </Column>
                                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column input-column-short">
                                      <label> Payout Method </label>
                                      <Select
                                        id="payoutMethod"
                                        name="payoutMethod"
                                        value={ accountForm.payoutMethod || "" }
                                        className={ " " + (accountForm.errors.has('payoutMethod') ? 'error-field' : "") }
                                        disabled={ this.isPayoutDisabled(accountForm.frozen, accountForm.currency, accountForm.payoutMethod) }
                                        required={ true }
                                        clearable={ false }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'payoutMethod') }
                                        options={ this.mapAccountPayoutMethods(accountForm.currency, accountForm.payoutMethod) }
                                      />
                                    </Column>
                                    <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column input-column-short">
                                      <label> Fx Pair </label>
                                      <Select
                                        id="fxPair"
                                        name="fxPair"
                                        value={ accountForm.fxPair || "" }
                                        className={ " " + (accountForm.errors.has('fxPair') ? 'error-field' : "") }
                                        disabled={ !accountForm.currency }
                                        required={ true }
                                        clearable={ false }
                                        onChange={ (event) => this.onAccountItemChange(event, index, 'fxPair') }
                                        options={ this.filterAccountFxPairs(accountForm.currency) }
                                      />
                                    </Column>
                                  </Row>
                                </Column>
                              </Row>
                            </Column>
                            { isAbleToEditAccounts && (
                              <Column flexGrow={ 1 } vertical='start' alignSelf='center' style={ {width: "30px"} }>
                                {!accountForm.id && (
                                  <button
                                    className="delete acc"
                                    onClick={ () => this.onDeleteAccountItem(index) }
                                  >
                                    X
                                  </button>
                                )}

                                {index === accountForms.length - 1 && (
                                  <button
                                    className="add acc"
                                    onClick={ () => this.onAddAccountItem() }
                                  >
                                    <span className="plus"> + </span>
                                  </button>
                                )}
                              </Column>
                            )}
                          </Row>
                        )}
                        {isAbleToEditAccounts && accountForms.length === 0 && defaultCompany && (
                          <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                            <Column flexGrow={ 1 } horizontal='start' alignSelf='start' className="input-column" style={ { flexDirection: 'row', alignItems: 'center' } }>
                              Add Account
                              <button
                                className="add"
                                onClick={ () => this.onAddAccountItem() }
                              >
                                <span className="plus"> + </span>
                              </button>
                            </Column>
                          </Row>
                        )}
                        <Row flexGrow={ 1 } horizontal='start' wrap vertical='end' style={ { width: '100%' } }>
                          <Row flexGrow={ 1 } wrap horizontal='start' vertical='end' alignSelf='start'>
                            <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column account">
                              <span/>
                            </Column>
                            <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column account">
                              <span/>
                            </Column>
                          </Row>
                          <Row flexGrow={ 1 } wrap horizontal='start' vertical='end' alignSelf='end'>
                            <Column flexGrow={ 1 } vertical='end' alignSelf='end' className="input-column account">
                              <span/>
                            </Column>
                            <Column flexGrow={ 1 } vertical='start' className="input-column account">
                              { viewOrEdit !== 1 ? (
                                <CustomButton
                                  title="Save"
                                  type="submit"
                                  disabled={ !accountForms.length || isSubmitting }
                                  onClick={ () => this.saveProviderAccounts() }
                                />) : <span/> }
                            </Column>
                          </Row>
                        </Row>
                      </div>
                    </>
                  )}
                </Panel.Body>
              </Panel>
            )}
          </Column>
        </Row>
      </Column>

      {showAlert && (
        <Alert
          show={ showAlert }
          title={ alertType }
          type={ alertType }
          text={ alertMessage }
          confirmButtonColor="#187EED"
          onConfirm={ this.onConfirm }
        />
      )}

      <PromptModal
        title="Warning!"
        message="This change will be applied to all mids from this provider & currency."
        handleConfirm={ this.handleConfirmPromptModal }
        handleClose={ this.handleClosePromptModal }
        isLoading={ false }
        showModal={ showPromptModal }
      />

    </Row>;
  }
};

export default ManagePSP;